<template>
  <div>
    <el-dialog
      title="隐形ID检测"
      :visible.sync="diaShow"
      :close-on-click-modal="false"
      width="700px"
      @close="closedetection"
    >
     <div style="margin-bottom: 20px;">
      <p v-if="hideIdCheckState=='01'" style="color:#0981FF;">本单已通过隐形ID检测</p>
      <p v-if="hideIdCheckState=='02'" style="color:red">本单未通过隐形ID检测，需店员重评，重评后默认账号不可退出</p>
     </div>
      <div style="margin-bottom: 20px">
        <el-table
          ref="addtipleTable"
          :data="selectedStoreList"
          tooltip-effect="dark"
          border
          max-height="640"
          style="width: 100%"
        >
          <el-table-column type="index" align="center" label="序号" width="50">
          </el-table-column>
          <el-table-column label="检测标题" align="center">
            <template slot-scope="{ row }">{{
              row.failureName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="检测结果" align="center">
            <template slot-scope="{ row }">{{
              row.optionName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="检测凭证" align="center">
            <template slot-scope="{ row }">
              <span class="blu_text"  @click="openImgBox(row)">查看</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 查看旧机照片 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="隐形ID检测凭证"
      :visible="imgShow"
      @close="imgClose"
      top="5%"
    >
      <div class="img-box">
        <div class="img-tips-box">
          <div
            class="img-tips"
            :class="imgShowOnly ? 'img-tips-default' : 'img-tips-choose'"
          >
            照片
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList.length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList.length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList">
              <el-carousel trigger="click" height="80vh" @change="getindex">
                <el-carousel-item v-for="item in DialogImgList" :key="item">
                  <div class="sec_img">
                    <img :src="item" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hideIdCheckState: {
      type: String,
      default: '',
    },
    selectedStoreList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      diaShow: false,
      imgShow: false,
      imgShowOnly: true,
      DialogImgList: [],
      imgShowIndex: 0,
      imgIndex: 0,
    };
  },
  watch: {
    show(val) {
      this.diaShow = val;
    },
  },
  created() {},
  mounted() {},
  methods: {
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    closedetection() {
      this.$emit("closedetection");
    },
    openImgBox(row) {
      this.imgShow = true;
      this.DialogImgList = row.voucherList;
      console.log("====", this.DialogImgList);
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
      this.imgList = [];
    },
  },
};
</script>

<style scoped lang="scss">
.blu_text {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }
.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}
// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }
}

</style>
