<template>
  <div class="OrderTable" v-loading="loading">
    <div class="top_bar">
      <div class="case" style="text-align: right">
        <div class="case-title">
          <img
            class="case-title_icon"
            src="@/assets/images/icon_lb@2x.png"
            alt
          />
          <div class="case-title_max">
            订单列表<span style="color: #ff5757; margin-left: 5px"
              >({{ this.pages.total }})</span
            >
          </div>
        </div>
      </div>
      <div>
        <!-- <el-button size="small" type="warning" @click="configuration">报价限制配置</el-button> -->
        <el-button
          size="small"
          type="warning"
          @click="$refs['quoteLimitSettingDia'].open()"
          >报价限制配置</el-button
        >
        <el-button
          size="small"
          type="success"
          icon="el-icon-download"
          @click="clickExcel"
          >导出Excel</el-button
        >
      </div>
    </div>
    <div v-if="this.pages.total > 0">
      <div
        v-for="(item, index) in orderTableList"
        :key="index"
        class="order_list"
      >
        <div class="line_top"></div>
        <div
          class="table_title"
          :style="{
            background:
              item.isOpenMatrix && item.isOpenMatrix !== '--'
                ? '#FEF3E2'
                : '#EAF1FF',
          }"
        >
          <div class="left">
            <div style="margin-left: 0">
              <div>{{ index + 1 }}</div>
            </div>
            <div>
              <span
                class="sty"
                style="color: #fff"
                v-if="item.recycleWay === '02' && item.channel == 'Store'"
              >
                独立回收
              </span>

              <span
                class="sty"
                style="color: #fff"
                v-if="item.recycleWay === '01' && item.channel == 'Store'"
              >
                以旧换新
              </span>

              <span
                class="sty"
                v-if="item.expressChannel == '01'"
                style="background: #7dc165; color: #fff"
                >繁星物流</span
              >
              <span
                class="sty"
                style="background: #84c0ff; color: #fff"
                v-if="item.expressChannel == '02'"
                >线下物流</span
              >
            </div>

            <div style="display: flex; align-items: center; margin-left: 15px">
              <span class="order-nice" v-if="item.isNiceBiz == true"
                >靓机订单</span
              >

              <span>订单号：</span>
              <span :id="'orderNo' + index">{{ item.orderNo }}</span>
              <span
                class="copy"
                @click="copy('orderNo' + index)"
                :style="{
                  background:
                    item.isOpenMatrix && item.isOpenMatrix !== '--'
                      ? '#4C84FF'
                      : '#FF6600',
                }"
                >复制</span
              >
            </div>
            <div>
              <span>下单时间：</span>
              <span>{{ item.orderTime }}</span>
            </div>
            <div class="flex">
              <span>询价商家：</span>
              <!-- <span>{{ item.companyName }}</span> -->
              <el-tooltip :content="item.companyName" placement="top">
                <span
                  style="
                    max-width: 150px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                  "
                  >{{ item.companyName }}</span
                >
              </el-tooltip>
            </div>
            <!--            <div v-if="item.outType == 'ygx' || item.outType == 'xm' || item.outType == 'lb'||item.outType == 'lb2'">-->
            <!--              <span>{{ item.outType == 'ygx' ? '一根线订单号' : item.outType == 'xm' ? '零售通订单号' : item.outType == 'lb' ? 'SD订单号' : 'BD订单号' }}：</span>-->
            <!--              <span>{{ item.orderNoOneline }}</span>-->
            <!--            </div>-->
            <!--            {{item.outType}}-->
            <!-- 是矩阵订单， 显示矩阵订单 -->
            <div
              style="font-size: '14px'; font-family: FZLanTingHei-DB-GBK"
              v-if="item.isOpenMatrix && item.isOpenMatrix !== '--'"
            >
              <span style="color: #0981ff">矩阵订单号：</span>
              <a
                v-if="item.matrixOrderNo === '--' || !item.matrixOrderNo"
                style="color: #0981ff; font-family: FZLanTingHei-DB-GBK"
                >--</a
              >
              <a
                v-else
                style="
                  text-decoration: underline;
                  cursor: pointer;
                  color: #0981ff;
                  font-size: 14px;
                  font-family: FZLanTingHei-DB-GBK;
                "
                :href="`/RecallOrder/Details?type=edit&id=${item.matrixOrderNo}`"
                target="_blank"
                >{{ item.matrixOrderNo }}</a
              >
            </div>
            <!-- 不是矩阵订单,但是外部渠道，显示外部订单号，不是矩阵订单，不是外部渠道，不显示 -->
            <!-- v-else-if="(item.outType && item.outType !== '--') && (!item.isOpenMatrix || item.isOpenMatrix === '--')"-->
            <div
              v-if="
                (!item.isOpenMatrix || item.isOpenMatrix === '--') &&
                item.outType &&
                item.outType !== '--'
              "
              class="flex"
              style="
                font-size: '14px';
                color: #ffa11b;
                font-family: FZLanTingHei-DB-GBK;
              "
            >
              <span style="color: #ffa11b">外部订单号：</span>
              <span
                v-if="!item.orderNoOneline || item.orderNoOneline === '--'"
                style="font-size: 14px; color: #ffa11b"
                >--</span
              >
              <span
                v-else-if="
                  item.orderNoOneline &&
                  item.orderNoOneline !== '--' &&
                  item.outType !== 'fx'
                "
                style="font-size: 14px; color: #ffa11b"
                >{{ item.orderNoOneline }}</span
              >
              <a
                v-else
                :href="`/RecallOrder/Details?type=edit&id=${item.orderNoOneline}`"
                style="
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: 14px;
                  color: #ffa11b;
                  font-family: FZLanTingHei-DB-GBK;
                "
                target="_blank"
                >{{ item.orderNoOneline }}</a
              >
            </div>
          </div>

          <div class="right">
            <div
              v-if="
                item.state != '10' &&
                item.state != '20' &&
                item.state != '30' &&
                item.isEnablePay == true
              "
              style="display: flex; align-items: center"
            >
              <span
                v-if="item.userIsCollect == true"
                style="
                  color: #80c269;
                  display: flex;
                  align-items: center;
                  margin-right: 20px;
                "
                >已到账门店，已付款给用户</span
              >
              <span
                v-else
                style="
                  color: #ff687b;
                  display: flex;
                  align-items: center;
                  margin-right: 20px;
                "
                >已到账门店，未付款给用户</span
              >
            </div>
            <div
              v-if="item.state == '30' && item.isEnablePay == true"
              style="display: flex; align-items: center"
            >
              <span
                v-if="item.returnedChannel == 'Store'"
                style="color: #80c269; margin-right: 20px"
                >门店退款</span
              >
              <span v-else style="color: #ff687b; margin-right: 20px"
                >拒收退回</span
              >
              <span
                v-if="item.userIsCollect == true"
                style="color: #80c269; margin-right: 20px"
                >顾客收款后退单</span
              >
              <span v-else style="color: #ff687b; margin-right: 20px"
                >顾客未收款退单</span
              >
            </div>
            <div
              v-if="item.state === '05' && item.channel == 'Store'"
              class="rightMid"
            >
              差异订单
            </div>

            <div class="rightFalseline" v-if="item.isEnablePay == true">
              线上支付
            </div>

            <!-- <div class="rightTrue" v-if="item.businessType === '01' && item.channel == 'Store'">系统报价</div> -->
            <!-- <div class="rightTrue" v-if="item.businessType === '02' && item.channel == 'Store'">工程师报价</div> -->
            <div class="rightMid" v-if="item.channel == 'Merchant'">
              回收商自建订单
            </div>
          </div>
        </div>

        <div class="table_list">
          <el-table :data="[item]" border style="width: 100%" id="excelOrder">
            <!-- *********************************修改*************************************** -->
            <el-table-column prop="phoneName" label="回收商品" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.phoneName }}</div>

                <!--                <div v-if="row.imei && row.imei != '&#45;&#45;'">-->
                <!--                  串号：{{ row.imei }}-->
                <!--                </div>-->
                <el-button
                  type="primary"
                  size="mini"
                  v-if="row.healthCode !== '--'"
                  >{{ row.healthCode }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="phoneName" label="旧机串号" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.imei || "--" }}</div>
                <p
                  class="lv_fc_line"
                  @click="getFunction(row.orderNo, row.outType)"
                >
                  功能情况
                </p>
                <p
                  v-if="row.hideIdCheckState !== '00'"
                  class="lv_fc_line"
                  @click="detectionfunction(row.orderNo, row.hideIdCheckState)"
                >
                  隐藏ID检测
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="typeName" label="回收分类" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.typeName }}</span>
                <div v-if="row.isNiceDevice == true">
                  <el-tag type="danger" size="small" effect="dark">
                    靓机</el-tag
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="旧机图片" align="center">
              <template slot-scope="{ row }">
                <div
                  class="images-box"
                  v-if="
                    row.images && row.images != '--' && row.images.length > 0
                  "
                >
                  <img :src="row.images[0].img" alt="" />
                  <span @click="openImgBox(row)" class="text-down"
                    >共{{ row.images.length }}张</span
                  >
                </div>
                <div v-else>暂无图片</div>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="订单状态" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.state === '00'">待确认</span>
                <span v-else-if="row.state === '01'">已确认</span>
                <span v-else-if="row.state === '02'">待支付</span>
                <span v-else-if="row.state === '03'">已绑码</span>
                <span v-else-if="row.state === '04'">已收货</span>
                <span v-else-if="row.state === '05'">降价收货</span>
                <span v-else-if="row.state === '10'">已取消</span>
                <span v-else-if="row.state === '20'">已作废</span>
                <span v-else-if="row.state === '30'">已退回</span>
                <div
                  @click="DialoghandleBtn(row)"
                  v-if="row.state === '10' || row.state === '20'"
                  class="text-down"
                >
                  {{
                    row.state === "10"
                      ? "取消原因"
                      : row.state === "20"
                      ? "作废原因"
                      : ""
                  }}
                </div>
                <div
                  @click="recoveryRecord(row)"
                  v-if="row.cancelRestoreNum"
                  class="text-down"
                >
                  恢复记录
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="storeName" label="询价门店" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.storeName }}</span>
                <div v-if="row.shopType && row.shopType != '--'">
                  <el-tag
                    size="mini"
                    :type="row.shopType == '1' ? 'danger' : ''"
                    effect="plain"
                  >
                    {{ row.shopType == "1" ? "直营店" : "专卖店" }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="phoneName" label="规格" align="center">
              <template slot-scope="{ row }">
                <span>
                  {{ row.phoneName == "--" ? "" : row.phoneName }}
                  {{ row.phoneMemory == "--" ? " " : row.phoneMemory }}
                  {{ row.phoneStorage == "--" ? "" : row.phoneStorage }}
                </span>
                <span
                  v-if="
                    row.phoneName == '--' &&
                    row.phoneMemory == '--' &&
                    row.phoneStorage == '--'
                  "
                  >--</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="staffName" label="询价人员" align="center">
              <template slot-scope="{ row }">
                <span style="display: block">{{ row.staffMobile }}</span>
                <span
                  v-if="row.isStaffSub == true"
                  style="cursor: pointer"
                  class="text-down"
                  @click="seeSubInfo(row)"
                  >{{ row.staffName }}</span
                >
                <span v-else>{{ row.staffName }}</span>
                <div
                  :style="
                    row.staffType == '03' && row.isStaffSub == true
                      ? 'cursor: pointer'
                      : ''
                  "
                >
                  <el-tag
                    type="success"
                    size="mini"
                    effect="dark"
                    @click="
                      row.staffType == '03' && row.isStaffSub == true
                        ? seeSubInfo(row)
                        : ''
                    "
                    >{{
                      !row.staffType || row.staffType == null
                        ? "--"
                        : row.isMajor == true
                        ? "创建人"
                        : row.staffType == "01"
                        ? "子账号"
                        : row.staffType == "02"
                        ? "总监"
                        : row.staffType == "03" && row.isStaffSub == true
                        ? "店长-从账号"
                        : row.staffType == "03"
                        ? "店长"
                        : "店员"
                    }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="prePrice"
              label="预估价"
              align="center"
              width="100px"
            >
              <template slot-scope="{ row }">
                <!--                || row.orderMode === '00'-->
                <div v-if="row.orderMode === '00'">
                  <span
                    v-if="
                      row.channelAssessShowPrice &&
                      row.channelAssessShowPrice != '--'
                    "
                    >￥{{ row.channelAssessShowPrice }}</span
                  >
                  <span v-else>￥0</span>
                  <div
                    v-if="
                      row.leftSideShowPrice != '--' &&
                      row.rightSideShowPrice != '--'
                    "
                  >
                    <span
                      >（{{ row.leftSideShowPrice }}-{{
                        row.rightSideShowPrice
                      }}）</span
                    >
                  </div>
                </div>
                <div v-else-if="row.isOneline == true">
                  <span v-if="row.prePrice && row.prePrice != '--'"
                    >￥{{ row.prePrice }}</span
                  >
                  <span v-else>￥0</span>
                  <div
                    v-if="row.preLowPrice != '--' && row.preHighPrice != '--'"
                  >
                    <span
                      >（{{ row.preLowPrice }}-{{ row.preHighPrice }}）</span
                    >
                  </div>
                </div>
                <span v-else>--</span>
                <div
                v-if="((row.prePrice && row.prePrice != '--')||(row.channelAssessShowPrice &&
                      row.channelAssessShowPrice != '--'))&&row.outType == 'honor'"
                  @click="seafuturePrices(row)"
                  style="
                    text-decoration: underline;
                    cursor: pointer;
                    color: #0c82ff;
                  "
                >
                  查看
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="avgInquiry" align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>报低价提醒值</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="报价≤低价提醒值时，回收商无法提交报价；提醒值为回收商当前最高报价/预估价*提醒比例，优先使用回收商报价"
                      placement="top"
                    >
                      <img
                        src="../../assets/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="{ row }">
                <div v-if="row.limitationLowPrice !== '--'">
                  <p>{{ row.limitationLowPrice || "--" }}</p>
                  <div
                    @click="$refs['limitPriceDia'].open(row, 'lower')"
                    style="
                      text-decoration: underline;
                      cursor: pointer;
                      color: #0c82ff;
                    "
                  >
                    查看
                  </div>
                </div>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="avgInquiry"
              label="报高价提醒值"
              align="center"
            >
              <template slot="header">
                <div class="img-el-heard">
                  <div>报高价提醒值</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="报价≥靓机价上浮一定比例时提醒，靓机价取该订单同机型同规格对应在分毫估价中的同机型、同规格、正常开机、全新未拆封的旧机报价"
                      placement="top"
                    >
                      <img
                        src="../../assets/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="{ row }">
                <div v-if="row.limitationWarnPrice != '--'">
                  <p>{{ row.limitationWarnPrice || "--" }}</p>
                  <span>限制值{{ row.limitationPrice || "--" }}</span>
                  <span
                    >({{
                      row.limitationRate + "%" + "," + row.limitationLimit
                    }})</span
                  >
                  <div
                    @click="$refs['limitPriceDia'].open(row, 'upper')"
                    style="
                      text-decoration: underline;
                      cursor: pointer;
                      color: #0c82ff;
                    "
                  >
                    查看
                  </div>
                </div>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="quoteMerchants"
              label="参与报价回收商"
              align="center"
            >
              <template slot-scope="{ row }">
                <span
                  v-if="row.quoteMerchants && row.quoteMerchants != '--'"
                  style="cursor: pointer"
                  class="text-down"
                  @click="openStoreList(row)"
                  >{{ row.quoteMerchants }}</span
                >
                <span v-else>{{ row.quoteMerchants }}</span>
                <p
                  v-if="
                    (row.outType == 'xm' || row.outType == 'ygx') &&
                    isopenSubsidy == '01' &&
                    row.state === '00'
                  "
                  @click="markupPrice(row.orderNo, row.outType)"
                  style="
                    color: rgb(255, 104, 123);
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  补贴加价
                </p>
                <!-- 推送报价按钮显示规则：仅外部订单-立本渠道的订单(lb,lb2)，繁星自有矩阵(fx)订单显示推送报价按钮，所有订单状态都需要展示 -->
                <p
                  @click="offerPush(row.orderNo, row.outType)"
                  v-if="judgmentouttype(row.outType)"
                  style="
                    color: rgb(255, 104, 123);
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  报价推送
                </p>
                <div>
                  <el-tag
                    v-if="row.isAuto && row.isAuto != '--'"
                    size="mini"
                    type="warning"
                    effect="dark"
                  >
                    自动报价
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="merchantName"
              label="成交回收商"
              align="center"
            >
              <template slot-scope="{ row }">
                <div>{{ row.merchantName }}</div>
                <el-tag type="warning" size="small" v-if="row.isInside == true"
                  >内部回收</el-tag
                >
              </template>
            </el-table-column>
            <!-- 新增差异基金 -->
            <el-table-column
              prop="diffFundPrice"
              label="差异基金"
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row.diffFundPrice || "--" }}</span>
              </template>
            </el-table-column>
            <!-- 新增成交商家报价quotePrice  -->
            <el-table-column
              prop="quotePrice"
              label="成交商家报价"
              align="center"
            >
              <template slot="header">
                <div class="img-el-heard">
                  <div>成交商家报价</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="指回收商原始报价减去预留差异基金金额"
                      placement="top"
                    >
                      <img
                        src="../../assets/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.quotePrice || "--" }}</span>
              </template>
            </el-table-column>
            <!-- 改文字：回收商成交金额->回收商实收价 -->
            <el-table-column
              prop="newPrice"
              label="回收商实收价"
              align="center"
            >
              <template slot="header">
                <div class="img-el-heard">
                  <div>回收商实收价</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="订单如有追差，为扣除追差金额后的成交商家报价（不含差异基金）"
                      placement="top"
                    >
                      <img
                        src="../../assets/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.newPrice || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="afterSubsidyQuotePrice"
              label="回收金额(含补贴)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="finalPrice"
              label="用户成交金额"
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row.finalPrice || "--" }}</span>
                <div
                  class="color-red"
                  @click="plusPrice(row)"
                  style="text-decoration: underline; cursor: pointer"
                  v-if="row.useAddPrice && row.useAddPrice != '--'"
                >
                  已加价：{{ row.useAddPrice }}元
                </div>
                <div
                  @click="$refs['quoteDetailsDia'].open(row.orderNo, row.state)"
                  style="
                    text-decoration: underline;
                    cursor: pointer;
                    color: #0c82ff;
                  "
                >
                  查看明细
                </div>
                <div
                  class="color-red"
                  @click="isInsideInfo(row)"
                  v-if="row.isInside == true"
                  style="text-decoration: underline; cursor: pointer"
                >
                  内部审批成交
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="niceAckPayment"
              label="付款方"
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{
                  row.niceAckPayment == "01"
                    ? "成交商家付款"
                    : row.niceAckPayment == "02"
                    ? "靓机商家付款"
                    : row.niceAckPayment
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="isBindBarCode"
              label="是否绑码"
              align="center"
            >
              <template slot-scope="{ row }">
                <span class="color-green" v-if="row.isBindBarCode == '01'"
                  >是</span
                >
                <span class="color-red" v-if="row.isBindBarCode == '02'"
                  >否</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="isBindBarCode"
              label="是否为重评订单"
              align="center"
            >
              <template slot-scope="{ row }">
                <div
                  v-if="
                    row.isOneline != true ||
                    (row.isOneline == true && row.isReBuild == true)
                  "
                >
                  <div
                    :class="row.isReBuild == true ? 'color-green' : 'color-red'"
                  >
                    {{ row.isReBuild == true ? "是" : "否" }}
                  </div>
                  <div v-if="row.isReBuild == true">
                    原订单：<span
                      style="
                        text-decoration: underline;
                        cursor: pointer;
                        color: #0981ff;
                      "
                      @click="toDetails(row)"
                      >{{ row.originalOrderNo }}</span
                    >
                  </div>
                </div>
                <!-- v-else -->
                <div v-else>
                  <div
                    :class="
                      row.isChxRebuild == true ? 'color-green' : 'color-red'
                    "
                  >
                    {{ row.isChxRebuild == true ? "是" : "否" }}
                  </div>
                  <div v-if="row.isChxRebuild == true">
                    <span
                      @click="viewDetails(row)"
                      style="
                        text-decoration: underline;
                        cursor: pointer;
                        color: #0981ff;
                      "
                      >查看详情</span
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="remakeCount" label="补拍记录" align="center">
              <template slot-scope="{ row }">
                <span
                  v-if="row.remakeCount && row.remakeCount != '--'"
                  style="cursor: pointer"
                  class="text-down"
                  @click="openRemakeList(row)"
                  >{{ row.remakeCount }}</span
                >
                <span v-else>{{ row.remakeCount }}</span>
                <div
                  v-if="
                    row.remakeCount &&
                    row.remakeCount != '--' &&
                    row.chxReimgOrderNo &&
                    row.chxReimgOrderNo != '--'
                  "
                >
                  补拍订单:<span
                    style="cursor: pointer"
                    class="text-down"
                    @click="remakeHandleClick(row)"
                    >{{ row.chxReimgOrderNo }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="hasExpected" label="议价信息" align="center">
              <template slot-scope="{ row }">
                <span
                  v-if="row.hasExpected && row.hasExpected != '--'"
                  style="cursor: pointer"
                  class="text-down"
                  @click="openExpected(row)"
                  >查看详情</span
                >
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="finalMakeUpPrice"
              label="平台加价"
              align="center"
            >
              <template slot-scope="{ row }">
                <span
                  v-if="row.finalMakeUpPrice && row.finalMakeUpPrice != '--'"
                  style="cursor: pointer"
                  class="text-down"
                  @click="seeFinalMakeUpPrice(row)"
                  >加价{{ row.finalMakeUpPrice }}元</span
                >
                <span v-else>{{ row.finalMakeUpPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="renewSubsidyPrice"
              label="换新补贴"
              align="center"
            >
              <template slot-scope="{ row }">
                <span
                  v-if="
                    row.renewSubsidyPrice != '--' && row.renewSubsidyPrice != 0
                  "
                  >{{ row.renewSubsidyWay == "00" ? "旧机加价" : "新机抵扣"
                  }}{{ row.renewSubsidyPrice }}元</span
                >
                <span v-else>--</span>
                <div
                  v-if="
                    row.renewSubsidyPrice != '--' && row.renewSubsidyPrice != 0
                  "
                  @click="goRenewOrder(row)"
                  style="
                    text-decoration: underline;
                    cursor: pointer;
                    color: #0c82ff;
                  "
                >
                  查看详情
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="expressNo" label="运单信息" align="center">
              <template slot-scope="{ row }">
                <div v-if="row.expressNo">{{ row.expressNo }}</div>
                <div
                  v-if="
                    row.expressNo != '--' &&
                    row.expressChannel != '01' &&
                    row.isSign == true
                  "
                >
                  已签收
                </div>
                <div v-if="row.expressNo && row.expressNo != '--'">
                  <el-tag
                    size="small"
                    :type="row.expressChannel == '01' ? '' : 'warning'"
                    effect="dark"
                  >
                    {{ row.expressChannel == "01" ? "繁星物流" : "线下物流" }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="signType" label="物流状态" align="center">
              <template slot-scope="{ row }">
                <span
                  :style="
                    row.signType == '01' || row.signType == '03'
                      ? 'color: #FF8080'
                      : ''
                  "
                  >{{
                    row.signType == "01"
                      ? "发货异常"
                      : row.signType == "02"
                      ? "未签收"
                      : row.signType == "03"
                      ? "超时未签收"
                      : row.signType == "04"
                      ? "已签收"
                      : "--"
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="receiptType" label="收货方" align="center">
              <template slot-scope="{ row }">
                <span>{{
                  row.receiptType == "01"
                    ? "回收商"
                    : row.receiptType == "02"
                    ? "平台"
                    : "--"
                }}</span>
                <div
                  class="text-down"
                  key="jh"
                  v-if="row.isShowReceiveAddress == true"
                  @click="seaAddress(row)"
                >
                  查看地址
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="channelAssessNo"
              label="估价单号"
              align="center"
            >
              <template slot-scope="{ row }">
                <span
                  @click="
                    openAction(
                      `/RecallOrder/evaluate?id=${row.channelAssessNo}`
                    )
                  "
                  class="lv_fc_line"
                  v-if="row.channelAssessNo && row.channelAssessNo !== '--'"
                  >{{ row.channelAssessNo }}</span
                >
                <span v-else>--</span>
              </template>
            </el-table-column>
            <!-- *********************************修改*************************************** -->
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="110"
            >
              <template slot-scope="{ row }">
                <el-button @click="handleClick(row)" type="primary" size="mini"
                  >订单详情</el-button
                >
                <!-- 加撤销收货按钮 -->
                <el-button
                  v-if="row.cancelReceipt && row.cancelReceipt !== '--'"
                  style="margin-left: 0; margin-top: 10px"
                  @click="cancelReceive(row)"
                  type="warning"
                  size="mini"
                  >撤销收货</el-button
                >
                <el-button
                  style="margin-left: 0; margin-top: 10px"
                  v-if="row.state === '10'"
                  @click="recoverClick(row)"
                  type="danger"
                  plain
                  size="mini"
                  >恢复订单
                </el-button>
                <el-button
                  v-if="
                    row.isOpenPlatFormMakeUp == '01' &&
                    row.isMakeUp != true &&
                    row.state == '00' &&
                    row.outType == 'xm'
                  "
                  style="margin-left: 0; margin-top: 10px"
                  @click="offerClick(row, 'up')"
                  type="warning"
                  size="mini"
                  >我要报价
                </el-button>
                <el-button
                  v-if="
                    row.isMakeUp != '--' &&
                    row.isMakeUp &&
                    row.state === '00' &&
                    row.isGetHighest != true
                  "
                  style="margin-left: 0; margin-top: 10px"
                  @click="offerClick(row, 'revoke')"
                  type="danger"
                  size="mini"
                  >撤销报价
                </el-button>
                <el-button
                  v-if="
                    row.outType &&
                    row.outType !== '--' &&
                    row.outType !== 'fx' &&
                    row.state === '00' &&
                    row.isHaveRecoverRecord &&
                    row.isHaveRecoverRecord !== '--'
                  "
                  type="primary"
                  size="mini"
                  style="
                    margin-left: 0;
                    margin-top: 10px;
                    background: #fff;
                    color: #0981ff;
                  "
                  @click="
                    dealProp = {
                      ...dealProp,
                      show: true,
                      code: '',
                      barCode: '',
                      currentData: row,
                    }
                  "
                >
                  确认成交
                </el-button>
                <!-- <el-button
                  v-if="row.userPayeeMode == '01' && !row.userIsCollect && ['01', '03'].includes[row.state]"
                  plain type="danger" size="mini" style="margin-left:0;margin-top:10px"
                  @click="recollection(row)"
                >
                  重新收款
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="orderPagina">
        <el-pagination
          background
          :current-page="pages.currentPage"
          :page-size="10"
          layout="total, prev, pager, next,jumper"
          :total="this.pages.total"
          class="el-pagination-c"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div
      v-else
      style="
        width: 100%;
        text-align: center;
        padding: 80px 0;
        font-size: 24px;
        color: #666666;
        background: white;
        font-weight: bold;
      "
    >
      暂无订单~
    </div>
    <!-- 撤销收货弹框 -->
    <el-dialog
      class="acc_dialog"
      title="操作提示"
      :visible.sync="cancelReceiveShow"
      :close-on-click-modal="false"
      width="540px"
      @closed="cancelReceiveClose"
    >
      <p class="lv_fc_red" style="margin-bottom: 20px">
        说明：撤销收货需要跟回收商确认，若订单与矩阵回收商成交，请前往矩阵订单处撤销。撤销后原订单将自动撤销收货。
      </p>
      <div
        style="
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 20px;
        "
      >
        是否确认撤销收货订单？
      </div>
      <div style="margin-bottom: 20px; font-weight: 600">
        <span style="color: red">*</span> 撤销收货原因
      </div>
      <el-input
        maxlength="50"
        type="textarea"
        :rows="4"
        placeholder="请输入原因，最多50字"
        v-model="cancelResonTxt"
      >
      </el-input>
      <div class="command" style="margin-top: 30px">
        <div>动态口令：</div>
        <el-input
          v-model="cancelCommandTex"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="cancelReceiveClose">取消</el-button>
        <el-button type="primary" @click.native="cancelReceiveSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 报价限制配置弹窗 -->
    <QuoteLimitSettingDia ref="quoteLimitSettingDia" />
    <!-- TODO 2.3.2 已抽离组件可删除 -->
    <el-dialog
      title="报价限制配置"
      :visible.sync="addPriceShow"
      :close-on-click-modal="false"
      width="1000px"
    >
      <div style="margin-bottom: 15px">
        <div class="biliStyle">
          <span>报价限制规则</span>
          <el-button
            size="mini"
            @click="editConfig()"
            icon="el-icon-plus"
            type="primary"
            round
            >添加规则
          </el-button>
        </div>
      </div>
      <div>
        <GlobalTable
          class="mt-20"
          style="padding-bottom: 20px"
          ref="GlobalTable"
          :columns="regionColumns"
          :data="regionList"
          :maxHeight="500"
          :isPagination="false"
        >
          <el-table-column
            label="同机型同规格靓机价区间"
            width="135px"
            slot="region"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="靓机价上浮比例"
            slot="limitationRate"
            align="center"
          >
            <template slot-scope="{ row }">
              <span style="color: #0981ff">{{ row.limitationRate }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            label="高于靓机上限"
            slot="limitationLimit"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.limitationLimit }}
            </template>
          </el-table-column>
          <el-table-column
            label="达到靓机价是否提醒"
            slot="operation"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="switchInsideBiz(row)"
                v-model="row.isWarn"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="达到限制值是否限制报价"
            slot="operation"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleSwitchUsed(row)"
                v-model="row.isBan"
              ></el-switch>
            </template>
          </el-table-column>

          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="editConfig(row)"
                >编辑</el-button
              >
              <el-button type="danger" size="mini" @click="deletConfig(row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addPriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitAddPrice()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑压价 -->
    <el-dialog
      title="编辑报价限制规则"
      :visible.sync="editPriceShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="editPriceClosed"
    >
      <el-form
        :model="editPriceForm"
        :rules="editPricerules"
        ref="editPriceRulesForm"
        label-width="83px"
      >
        <el-form-item label="靓机价区间:" label-width="90px">
          <el-col :span="11">
            <el-form-item>
              <el-input
                size="small"
                placeholder="最低靓机价"
                v-model.trim="editPriceForm.lower"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col style="text-align: center" :span="2">~</el-col>
          <el-col :span="11">
            <el-form-item>
              <el-input
                size="small"
                maxlength="5"
                placeholder="最高靓机价"
                v-model.trim="editPriceForm.upper"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item
          label="上浮报价比例:"
          prop="limitationRate"
          label-width="110px"
        >
          <el-input
            size="small"
            min="0"
            max="100"
            placeholder="输入上浮报价比例"
            v-model.trim="editPriceForm.limitationRate"
            @input="limitInput($event, 'limitationRate')"
            @blur="inputBlur('limitationRate')"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="上浮报价上限:"
          label-width="110px"
          prop="limitationLimit"
        >
          <el-input
            size="small"
            placeholder="输入上浮报价上限"
            maxlength="5"
            v-model.trim="editPriceForm.limitationLimit"
            @input="limitationLimitChange"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="editPriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitEditPrice()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 压价设置提示框 -->
    <el-dialog
      class="acc_dialog"
      title="提示"
      :visible.sync="yajiaShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="yajiaShow = false"
    >
      <div
        style="
          margin-bottom: 20px;
          font-weight: 500;
          text-align: center;
          font-size: 18px;
        "
      >
        是否确认修改报价限制配置？
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input
          v-model="yaCommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="yajiaShow = false">取消</el-button>
        <el-button type="primary" @click.native="yaPricesure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 推送报价 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${820}px`"
      title="报价推送记录"
      :visible="pricePushshow"
      @close="viewrecordsClose"
      top="15%"
      bottom="10%"
    >
      <div class="topPriceshoe">
        <div class="tp_left">
          <div class="tpl_first">
            <p>{{ allQuote.merchantNameLock || "--" }}</p>
            <div class="flost_tf">当前锁定</div>
          </div>
          <div class="center_tf">
            <div>
              <p style="color: #4c84ff">{{ allQuote.quotePriceLock || 0 }}</p>
              <p>报价</p>
            </div>
          </div>
        </div>
        <div class="tp_left">
          <div class="tpl_first">
            <p>{{ allQuote.merchantNameMax || "--" }}</p>
            <div class="flost_tf" style="background: #50cd85">当前最高</div>
          </div>
          <div class="center_tf">
            <div>
              <p style="color: #4c84ff">{{ allQuote.quotePriceMax || 0 }}</p>
              <p>报价</p>
            </div>
          </div>
          <div class="center_tf"></div>
        </div>
        <div class="tp_left" style="width: 134px; border-radius: 4px">
          <div class="center_tf">
            <div>
              <p style="color: #ffaf29">
                {{ allQuote.quotePricePushLock || 0 }}
              </p>
              <p>当前推送报价</p>
            </div>
          </div>
        </div>
      </div>
      <div class="center_ptcon">报价推送记录</div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 18px;
        "
      >
        <div
          class="record_content"
          :style="{
            width:
              outType.indexOf('fx') === -1 &&
              outType != 'lb' &&
              outType != 'lb2' &&
              outType != 'honor'
                ? '200px'
                : '',
          }"
        >
          <div
            :class="{ scllo_center: true, action_scoll: switchid == item.id }"
            @click="switchover(item.id)"
            v-for="item in copykeepList"
            :key="item.id"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          style="color: #ff687b; display: flex; align-items: center"
          v-if="outType == 'honor' && Closingoffer"
        >
          <el-statistic
            :value-style="{
              'font-size': '14px',
              color: '#FF687B',
              width: '50px',
            }"
            ref="statistic"
            @finish="hilarity"
            format="mm:ss"
            v-model="deadline4"
            time-indices
          >
          </el-statistic>
          <span>后结束报价</span>
        </div>
      </div>
      <div class="table_list autoshow" style="padding-bottom: 20px">
        <el-table
          :row-key="getRowKeys"
          :data="pushPricedatalist"
          max-height="300"
          border
          style="width: 100%"
          id="updatedOrder"
        >
          <el-table-column
            type="index"
            :index="
              (index) => {
                return index + 1;
              }
            "
            width="70"
            label="推送次序"
            align="center"
          ></el-table-column>
          <el-table-column min-width="100" label="推送报价" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.pushQuotePrice || "--" }}</span>
              <div>
                <el-tag
                  type="warning"
                  size="small"
                  effect="plain"
                  v-if="row.isCurrentGet"
                  >当前取价</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            width="160"
            label="推送时间"
            align="center"
          ></el-table-column>
          <el-table-column label="推送方式" align="center">
            <template slot-scope="{ row }">
              <p v-if="row.pushWay == 'sys'">系统推送</p>
              <p v-else class="handsStyle" @click="operationPush(row)">
                手动推送
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="pushMerchantName"
            width="100"
            label="锁定回收商"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>
                {{ row.pushMerchantName }}
              </span>
              <div>
                <el-tag
                  type="danger"
                  size="small"
                  effect="plain"
                  v-if="row.isLock"
                  >锁定</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="quotePrice"
            width="100"
            label="矩阵回收商报价"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="diffFundPrice"
            width="160"
            label="距离下单多久推送"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>{{ getTimes(row.duration || 0) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click.native="pricePushshow = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="codeLoading"
          @click.native="hdstandsSubmit"
          >手动推送报价</el-button
        >
      </span>
    </el-dialog>
    <!-- 手动推送报价 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${820}px`"
      title="手动推送报价"
      :visible="Manualpushshow"
      @close="Manualpushshow = false"
      top="15%"
      bottom="10%"
    >
      <div class="topPriceshoe">
        <div class="tp_left">
          <div class="tpl_first">
            <p>{{ allQuote.merchantNameLock || "--" }}</p>
            <div class="flost_tf">当前锁定</div>
          </div>
          <div class="center_tf">
            <div>
              <p style="color: #4c84ff">{{ allQuote.quotePriceLock || 0 }}</p>
              <p>报价</p>
            </div>
          </div>
        </div>
        <div class="tp_left">
          <div class="tpl_first">
            <p>{{ allQuote.merchantNameMax || "--" }}</p>
            <div class="flost_tf" style="background: #50cd85">当前最高</div>
          </div>
          <div class="center_tf">
            <div>
              <p style="color: #4c84ff">{{ allQuote.quotePriceMax || 0 }}</p>
              <p>报价</p>
            </div>
          </div>
        </div>
        <div class="tp_left" style="width: 134px; border-radius: 4px">
          <div class="center_tf">
            <div>
              <p style="color: #ffaf29">
                {{ allQuote.quotePricePushLock || 0 }}
              </p>
              <p>当前推送报价</p>
            </div>
          </div>
        </div>
      </div>
      <div class="writquotation">
        <p style="margin-bottom: 15px">
          填写报价<span>（修改报价后需先保存再提交）</span>
        </p>
        <div style="display: flex">
          <div style="width: 400px">
            <el-input
              :disabled="issave"
              v-model="Pushprice"
              maxlength="5"
              @input="pushchange"
              oninput="value=value.replace(/^0|[^0-9]/g,'')"
              placeholder="请输入报价"
            ></el-input>
          </div>
          <el-button
            size="small"
            type="primary"
            v-if="issave"
            @click="issave = false"
            >修改价格</el-button
          >
          <el-button size="small" type="success" v-else @click="Reserveprice"
            >保存价格</el-button
          >
        </div>
        <div style="display: flex" v-if="issave">
          <div class="baocunPrice">
            <div
              class="center_tf"
              v-for="(item, index) in priceAllList"
              :key="index"
            >
              <div class="bp_pstyle">
                <p style="color: #4c84ff">{{ item.num }}</p>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div class="tpl_first xiuga_center">
            <div class="bp_pstyle">
              <p style="color: #4c84ff">
                {{ calculationamount.quotePriceWillLock }}
              </p>
              <p style="text-align: center">
                {{ calculationamount.merchantNameWillLock }}
              </p>
            </div>
            <div class="flost_tf">即将锁定</div>
          </div>
        </div>
      </div>
      <div class="writquotation" v-if="issave">
        <div style="margin-bottom: 15px; display: flex">
          报价备注
          <div class="title_wt" v-if="calculationamount.priceState == '01'">
            改低
          </div>
          <div
            class="title_wt title_ht"
            v-if="calculationamount.priceState == '00'"
          >
            改高
          </div>
        </div>
        <div class="qb_act" v-if="calculationamount.priceState == '01'">
          <div
            class="qukliy_box"
            @click="remarkAdd('下单选错型号，核实后需改价')"
          >
            下单选错型号，核实后需改价
          </div>
          <div
            class="qukliy_box"
            @click="remarkAdd('多输/错输价格改价，望理解，感谢')"
          >
            多输/错输价格改价，望理解，感谢
          </div>
        </div>
        <div class="qb_act" v-if="calculationamount.priceState == '00'">
          <div class="qukliy_box" @click="remarkAdd('务必退出ID,解开锁屏密码')">
            务必退出ID,解开锁屏密码
          </div>
          <div
            class="qukliy_box"
            @click="remarkAdd('保证功能完好，与下单描述一致')"
          >
            保证功能完好，与下单描述一致
          </div>
          <div
            class="qukliy_box"
            @click="remarkAdd('确保触屏，面容正常，否则差价大')"
          >
            确保触屏，面容正常，否则差价大
          </div>
        </div>
        <div>
          <el-input
            maxlength="100"
            type="textarea"
            placeholder="请输入原因说明（限100字）"
            v-model="Quotationremarks"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click.native="Manualpushshow = false"
          >取消</el-button
        >
        <el-button
          type="info"
          key="1hk"
          size="small"
          v-if="!Pushprice || !issave"
          >提交报价</el-button
        >
        <el-button
          type="primary"
          key="vgng"
          size="small"
          v-else
          :loading="codeLoading"
          @click.native="eventualSubmit"
          >提交报价</el-button
        >
      </span>
    </el-dialog>
    <!-- 手动推送操作记录 -->
    <el-dialog
      title="手动推送操作记录"
      :visible.sync="handsRecordershow"
      :close-on-click-modal="false"
      width="500px"
    >
      <div>
        <div style="display: flex">
          <p style="margin-right: 40px">
            操作报价：<span style="color: #ff687b">{{
              operationdatalist.quotePrice || 0
            }}</span>
          </p>
          <p>
            推送矩阵原订单报价：<span style="color: #ffaf29">{{
              operationdatalist.pushQuotePrice || 0
            }}</span>
          </p>
        </div>
        <div style="margin: 20px 0">
          手动报价人：{{ operationdatalist.pushAdminName }}-{{
            operationdatalist.pushAdminAcc
          }}
        </div>
        <div>报价时间：{{ operationdatalist.createTime || "--" }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handsRecordershow = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <!-- 恢复记录弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`700px`"
      title="恢复记录"
      :visible="recorderShow"
      @close="recorderClose"
      top="15%"
    >
      <div class="img-box">
        <div class="table_list">
          <el-table
            :data="recorderList"
            border
            style="width: 100%"
            id="excelOrder"
            :cell-style="rowStyle"
            @cell-click="Bargain"
            max-height="400"
          >
            <el-table-column
              prop="index"
              type="index"
              align="center"
              label="序号"
            ></el-table-column>
            <el-table-column prop="restoreType" align="center" label="恢复类型">
              <template slot-scope="{ row }">
                {{ row.restoreType == "00" ? "已取消恢复" : "超时未付款恢复" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="restoreTime"
              align="center"
              label="恢复时间"
            ></el-table-column>
            <el-table-column prop="isNiceDevice" label="恢复人" align="center">
              <template scope="{ row }">
                {{ row.restoreName }}-{{ row.restoreMobile }}
                <template v-if="row.channel == '02'">（门店后台）</template>
              </template>
            </el-table-column>
            <el-table-column
              prop="restoreCause"
              label="恢复原因"
              align="center"
            >
              <template scope="{ row }">
                {{ row.restoreCause || "--" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="foot_buttom">
        <div></div>
        <el-button size="mini" @click="recorderShow = false" type="primary"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <!-- 导出Excel谷歌验证 -->
    <el-dialog
      title="操作提示"
      :visible.sync="codeShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="closeExport"
    >
      <div class="cance-tip">
        <div class="tip-sure">是否确认导出订单？</div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="code"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="closeExport">取消</el-button>
        <el-button
          type="primary"
          :loading="codeLoading"
          @click.native="codeSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="查看旧机照片"
      :visible="imgShow"
      @close="imgClose"
      top="5%"
    >
      <div class="img-box">
        <div class="img-tips-box">
          <div
            @click="chooseImages(0)"
            class="img-tips"
            :class="imgShowOnly ? 'img-tips-choose' : 'img-tips-default'"
          >
            旧机照片
          </div>
          <div
            v-if="this.DialogImgList[1].length > 0"
            @click="chooseImages(1)"
            class="img-tips"
            :class="imgShowOnly ? 'img-tips-default' : 'img-tips-choose'"
          >
            补拍照片
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList[imgIndex].length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList[imgIndex].length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList[imgIndex]">
              <el-carousel
                trigger="click"
                height="80vh"
                v-if="imgShow"
                @change="getindex"
              >
                <el-carousel-item
                  v-for="item in this.DialogImgList[this.imgIndex]"
                  :key="item.imgNo"
                >
                  <div class="sec_img">
                    <img :src="item.img" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
          <!-- <LookImg :images="imgList"/> -->
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :width="`80%`"
      title="查看参与报价回收商"
      :visible="storeShow"
      @close="storeClose"
      top="4%"
    >
      <div class="img-box">
        <div class="noteLayout">
          <p class="lv_fc_red" style="margin-bottom: 10px">
            说明：若该门店商户预留差异基金，则门店所看到的报价金额需加上差异基金才是商户的初始报价
          </p>
          <div>
            <el-button
              type="warning"
              size="mini"
              plain
              @click="handleConfigColumns"
              >配置列数据</el-button
            >
            <el-button
              v-if="quotePriceData.length != 0"
              type="text"
              @click="onQuotePrice"
            >
              已邀请{{ quotePriceData.length }}家外部商家报价>
            </el-button>
          </div>
        </div>
        <div class="table_list">
          <GlobalTable
            ref="GlobalTable"
            v-loading="storeLoading"
            v-if="configRefresh"
            :columns="storeColumns"
            :data="storeList"
            :isPagination="false"
            @handleCurrentChange="handleCurrentChange"
          >
            <el-table-column
              slot="recycleMerchantName"
              label="报价商家"
              align="center"
              width="200"
            >
              <template v-slot="{ row }">
                <el-tag
                  v-if="row.recycleMerchantName == merchantName"
                  size="mini"
                  type="danger"
                  effect="dark"
                  style="margin-right: 10px"
                >
                  成交
                </el-tag>
                <span>{{ row.recycleMerchantName }}</span>
                <el-tag
                  v-if="
                    row.isStandardRecycleMerchant &&
                    row.isStandardRecycleMerchant !== '--'
                  "
                  size="mini"
                  type="warning"
                  style="margin-left: 10px"
                  >虚拟基数</el-tag
                >
                <div>
                  <div
                    style="
                      background: rgb(245, 245, 255);
                      border-radius: 4px;
                      padding: 0px 8px;
                      margin-top: 4px;
                      display: inline-block;
                    "
                    v-if="
                      row.virtualName &&
                      baoPricestate == '00' &&
                      row.virtualName !== '--'
                    "
                  >
                    {{ row.virtualName }}
                  </div>
                </div>
                <div>
                  <el-tag
                    v-if="
                      row.isFixHighQuote &&
                      row.recycleMerchantName != merchantName &&
                      row.isFixHighQuote != '--'
                    "
                    size="mini"
                    type="warning"
                    effect="dark"
                    style="margin-right: 10px"
                  >
                    首次取价
                  </el-tag>
                  <el-tag
                    v-if="
                      row.isFixExpPrice &&
                      row.recycleMerchantName != merchantName &&
                      row.isFixExpPrice != '--'
                    "
                    size="mini"
                    type="warning"
                    effect="dark"
                  >
                    议价取价
                  </el-tag>
                  <el-tag
                    v-if="row.isAddPrice && row.isAddPrice != '--'"
                    size="mini"
                    type="success"
                    effect="dark"
                  >
                    平台加价
                  </el-tag>
                </div>
                <div class="isdeal1" v-if="row.isOutMerchant == true">外部</div>
              </template>
            </el-table-column>
            <el-table-column
              slot="originalQuotePrice"
              label="报价金额（含差异基金）"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.originalQuotePrice }}
                <div v-if="row.isMockQuoteOpen">
                  虚拟报价：<span class="price"
                    >¥{{ row.mkOriginalQuotePrice }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column slot="quotePrice" label="报价金额" align="center">
              <template scope="{ row }">
                {{ row.quotePrice }}
                <p v-if="row.isMockQuoteOpen">
                  虚拟报价：<span class="price">¥{{ row.mkQuotePrice }}</span>
                </p>
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              slot="staffName"
              label="报价人"
              align="center"
            >
              <template scope="{ row }">
                <template v-if="row.isMockQuoteOpen">
                  {{ row.mkStaffName }}
                  <span
                    v-if="
                      row.mkQuoteStaffPhone && row.mkQuoteStaffPhone != '--'
                    "
                    >- {{ row.mkQuoteStaffPhone }}</span
                  >
                  <span
                    v-if="
                      row.mkQuotePositionType && row.mkQuotePositionType != '--'
                    "
                    >-
                    {{
                      row.mkQuoteIsMajor === true
                        ? "创建人"
                        : row.mkQuotePositionType == "01"
                        ? "子账号"
                        : row.mkQuotePositionType == "02"
                        ? "报价师"
                        : "--"
                    }}</span
                  >
                </template>
                <template v-else>
                  {{ row.staffName }}
                  <span v-if="row.staffMobile && row.staffMobile != '--'"
                    >- {{ row.staffMobile }}</span
                  >
                  <span v-if="row.staffType && row.staffType != '--'"
                    >（{{ row.staffType }}）</span
                  >
                </template>
              </template>
            </el-table-column>
            <el-table-column slot="quoteNum" label="报价次数" align="center">
              <template scope="{ row }">
                <span
                  v-if="row.quoteNum != '--' && row.quoteNum != 0"
                  style="
                    text-decoration: underline;
                    cursor: pointer;
                    color: #0981ff;
                  "
                  @click="Bargain(row, 'config')"
                  >{{ row.quoteNum }}</span
                >
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column slot="state" label="报价状态" align="center">
              <template scope="{ row }">
                <span>{{
                  row.state == "00-0"
                    ? "待报价"
                    : row.state == "00-1"
                    ? "报价中"
                    : row.state == "00-2"
                    ? "已报价"
                    : row.state == "01"
                    ? "已确认"
                    : row.state == "10" && row.exclusiveCancelTime != "--"
                    ? `取消抢单${row.exclusiveCancelTime}`
                    : "交易取消"
                }}</span>
                <p v-if="row.isMockQuoteOpen && row.mkQuoteState == '01'">
                  <span class="price">虚拟报价</span>
                </p>
              </template>
            </el-table-column>
            <el-table-column
              slot="isNiceDevice"
              width="100"
              label="是否标记靓机"
              align="center"
            >
              <template scope="{ row }">
                <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column slot="quoteNote" label="报价备注" align="center">
              <template scope="{ row }">
                <el-tooltip
                  v-if="row.quoteNote != '--'"
                  class="item"
                  effect="dark"
                  :content="row.quoteNote == '--' ? '' : row.quoteNote"
                  placement="bottom"
                >
                  <span class="quoteNotecss">{{ row.quoteNote }}</span>
                </el-tooltip>

                <span v-else>{{ row.quoteNote }}</span>
              </template>
            </el-table-column>
            <el-table-column
              slot="diffFundPrice"
              label="差异基金"
              align="center"
            >
              <template scope="{ row }">
                {{ row.diffFundPrice }}
                <p v-if="row.isMockQuoteOpen">
                  虚拟：<span class="price">{{ row.mkDiffFundPrice }}</span>
                </p>
              </template>
            </el-table-column>
            <el-table-column
              slot="quoteChannelServerFee"
              width="100"
              label="渠道服务费"
              align="center"
            >
              <template scope="{ row }">
                {{ row.quoteChannelServerFee }}
                <div
                  v-if="
                    row.quoteChannelServerFee &&
                    row.quoteChannelServerFee != '--'
                  "
                  style="color: red"
                >
                  {{ row.matchServerFeeOnline == "01" ? "线上" : "线下" }}
                </div>
                <p v-if="row.isMockQuoteOpen">
                  虚拟：<span class="price">{{ row.mkChannelServerFee }}</span>
                </p>
              </template>
            </el-table-column>
            <el-table-column
              slot="commission"
              width="100"
              label="外部预扣手续费"
              align="center"
            >
              <template scope="{ row }">
                <span v-if="row.commission">{{ row.commission || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              slot="limitExclusiveLength"
              label="限时报价"
              align="center"
            >
              <template scope="{ row }">
                <span>{{
                  row.limitExclusiveLength == "--"
                    ? "不限制"
                    : row.limitExclusiveLength + "分钟"
                }}</span>
              </template>
            </el-table-column>
          </GlobalTable>
          <!-- </el-table> -->
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="查看报价记录"
      :visible="viewrecords"
      @close="viewrecordsClose"
      top="15%"
      bottom="10%"
    >
      <div class="table_list autoshow" style="padding-bottom: 20px">
        <el-table
          :data="getUpdatedQuoteLog"
          max-height="300"
          border
          style="width: 100%"
          id="updatedOrder"
        >
          <el-table-column
            prop="rank"
            width="70"
            label="报价次序"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="quoteTime"
            width="160"
            label="报价时间"
            align="center"
          ></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span
                >{{ row.exclusiveQuoterName }}-{{
                  row.isMajor
                    ? "创建人"
                    : row.positionType == "01"
                    ? "子账号"
                    : "报价师"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag
                  type="danger"
                  size="small"
                  effect="plain"
                  v-if="row.isInside"
                  >内部加价</el-tag
                >
              </div>
              <p v-if="row.isMockQuoteOpen">
                虚拟报价：<span class="price">¥{{ row.mkQuotePrice }}</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.quoteNote"
                class="item"
                effect="dark"
                :content="row.quoteNote"
                placement="top"
              >
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column
            prop="originalQuotePrice"
            width="100"
            label="报价金额（含差异基金）"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>￥{{ row.originalQuotePrice }}</span>
              <p v-if="row.isMockQuoteOpen">
                虚拟报价：<span class="price"
                  >¥{{ row.mkOriginalQuotePrice }}</span
                >
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="diffFundPrice"
            width="100"
            label="差异基金"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>￥{{ row.diffFundPrice }}</span>
              <p v-if="row.isMockQuoteOpen">
                虚拟：<span class="price">{{ row.mkDiffFundPrice }}</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="quoteChannelServerFee"
            width="100"
            label="渠道服务费"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>￥{{ row.quoteChannelServerFee }}</span>
              <p v-if="row.isMockQuoteOpen">
                虚拟：<span class="price">{{ row.mkChannelServerFee }}</span>
              </p>
            </template>
          </el-table-column>
        </el-table>
        <div
          style="color: #0981ff; font-size: 16px; margin: 20px 0"
          v-if="remakeQuoteLog && remakeQuoteLog.length"
        >
          补拍后报价记录
        </div>
        <el-table
          v-if="remakeQuoteLog && remakeQuoteLog.length"
          :data="remakeQuoteLog"
          max-height="300"
          border
          style="width: 100%"
          id="remakeOrder"
        >
          <el-table-column
            prop="rank"
            width="70"
            label="报价次序"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remakeTime"
            width="100"
            label="补拍时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="quoteTime"
            width="100"
            label="报价时间"
            align="center"
          ></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span
                >{{ row.exclusiveQuoterName }}-{{
                  row.isMajor
                    ? "创建人"
                    : row.positionType == "01"
                    ? "子账号"
                    : "报价师"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag
                  type="danger"
                  size="small"
                  effect="plain"
                  v-if="row.isInside"
                  >内部加价</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.quoteNote"
                class="item"
                effect="dark"
                :content="row.quoteNote"
                placement="top"
              >
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column
            prop="originalQuotePrice"
            width="100"
            label="报价金额（含差异基金）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="diffFundPrice"
            width="100"
            label="差异基金"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="quoteChannelServerFee"
            width="100"
            label="渠道服务费"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <GlobalDialog
      :title="dialogTitle"
      :show="dialogVisibleOrder"
      :width="600"
      top="10vh"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
      @onClose="resetPopupData"
    >
      <span>{{ dialogcancel.remark || "无" }}</span>
    </GlobalDialog>
    <!-- 恢复订单 -->
    <el-dialog
      title="恢复订单提示"
      :visible.sync="putDialogShow"
      :close-on-click-modal="false"
      width="550px"
      @closed="putDialogClosed"
    >
      <div class="cance-tip">
        <div>
          说明：恢复后将在距离最近的下次整点或半点，即系统执行定时任务时再次取消。
        </div>
        <div class="tip-sure">是否确认恢复订单？</div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="putDialogShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="putBtnLoading"
          @click.native="putDialogSubmit('00')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 重新收款 -->
    <el-dialog
      title="操作提示"
      :visible.sync="recollectionDialogShow"
      :close-on-click-modal="false"
      width="550px"
    >
      <div class="cance-tip">
        <div>
          说明：重新发起收款后，订单将变为待支付状态，将会在下次整点或半点再次超时
        </div>
        <div class="tip-sure">是否确认恢复为待付款状态？</div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="recollectionDialogShow = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="putBtnLoading"
          @click.native="putDialogSubmit('01')"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      class="add_price_dialog"
      title="加价详情"
      :visible.sync="plusPriceIF"
      :close-on-click-modal="false"
      width="650px"
      @closed="revokeClose"
    >
      <div v-if="pcaddPric && pcaddPric.addPrice">
        <div class="add-price-title">返利加价</div>
        <div class="add-price-conent">
          <ul class="onlyul">
            <li class="firstli">
              <div>
                申请加价金额: ￥{{ pcaddPric.addPrice || "" }}
                <span
                  class="firstli_span"
                  v-if="pcaddPric.addPriceUseType == '02'"
                  >(可加价范围: 1-{{ pcaddPric.addMaxPrice }})</span
                >
                <span
                  class="firstli_span"
                  v-if="pcaddPric.addPriceUseType == '01'"
                  >(挪用返利比例: {{ pcaddPric.addPriceRate }}%)</span
                >
              </div>
              <div>
                申请人员: {{ pcaddPric.applyStaffName }}-{{
                  pcaddPric.applyStaffMobile
                }}-{{
                  pcaddPric.applyIsMajor
                    ? "创建人"
                    : pcaddPric.applyStaffType == "01"
                    ? "子账号"
                    : pcaddPric.applyStaffType == "02"
                    ? "总监"
                    : pcaddPric.applyStaffType == "03"
                    ? "店长"
                    : pcaddPric.applyStaffType == "04"
                    ? "店员"
                    : ""
                }}
              </div>
            </li>
            <li class="secondli">
              <div>批准加价金额: ￥{{ pcaddPric.passAddPrice }}</div>
              <div v-if="pcaddPric.auditStaffName">
                审核授权人:{{ pcaddPric.auditStaffName }}-{{
                  pcaddPric.auditStaffMobile
                }}-{{
                  pcaddPric.auditIsMajor
                    ? "创建人"
                    : pcaddPric.auditStaffType == "01"
                    ? "子账号"
                    : pcaddPric.auditStaffType == "02"
                    ? "总监"
                    : pcaddPric.auditStaffType == "03"
                    ? "店长"
                    : pcaddPric.auditStaffType == "04"
                    ? "店员"
                    : ""
                }}
              </div>
            </li>
            <li style="margin-bottom: 20px" v-if="pcaddPric.auditStaffName">
              审核授权时间: {{ pcaddPric.auditTime }}
            </li>
            <li
              style="margin-bottom: 9px; height: 1px"
              v-if="!pcaddPric.auditStaffName"
            ></li>
          </ul>
        </div>
      </div>
      <div v-if="pcaddPric && pcaddPric.usdAllAngPow">
        <div class="add-price-title">红包加价</div>
        <div class="add-price-conent">
          <ul class="onlyul">
            <li class="firstli">
              使用红包加价：￥{{ pcaddPric.usdAllAngPow || 0 }} (店员红包：￥{{
                pcaddPric.usdAngPowStaff || 0
              }}，店长红包：￥{{ pcaddPric.usdAngPowSmStaff || 0 }})
            </li>
            <li class="secondli">
              剩余红包：￥{{ pcaddPric.lastAllAngPow }} (店员红包：￥{{
                pcaddPric.angPowStaff || 0
              }}，店长红包：￥{{ pcaddPric.angPowSmStaff || 0 }})
            </li>
            <li style="margin-bottom: 9px; height: 1px"></li>
          </ul>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="从账号信息"
      :visible.sync="subInfoShow"
      :close-on-click-modal="false"
      width="300px"
      @closed="subInfoClose"
    >
      <div style="font-size: 14px; color: #333333; padding-bottom: 21px">
        <div class="flex" style="margin-bottom: 20px">
          <span style="margin-bottom: 10px">微信头像：</span
          ><img
            style="width: 70px; height: 70px; border-radius: 50%"
            :src="subInfo.img"
            alt=""
          />
        </div>
        <div class="flex" style="margin-bottom: 20px">
          <span>微信昵称：</span
          ><span style="color: #666666">{{ subInfo.nickName }}</span>
        </div>
        <div class="flex" style="margin-bottom: 20px">
          <span>从账号姓名：</span
          ><span style="color: #666666">{{ subInfo.staffName }}</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class="acc_dialog"
      title="内部加价详情"
      :visible.sync="insideInfoShow"
      :close-on-click-modal="false"
      width="500px"
    >
      <ul class="onlyul">
        <li class="firstli">
          期望成交金额: ￥{{ insideInfo.applyRealPrice || "--" }}
        </li>
        <li class="secondli">
          批准成交金额: ￥{{ insideInfo.passAddPrice || "--" }}
        </li>
        <li>
          申请人员: {{ insideInfo.companyApplyStaffName }}-{{
            insideInfo.companyApplyStaffMobile
          }}-{{
            insideInfo.companyApplyIsMajor
              ? "创建人"
              : insideInfo.companyApplyStaffType == "01"
              ? "子账号"
              : insideInfo.companyApplyStaffType == "02"
              ? "总监"
              : insideInfo.companyApplyStaffType == "03"
              ? "店长"
              : insideInfo.companyApplyStaffType == "04"
              ? "店员"
              : ""
          }}
        </li>

        <li v-if="insideInfo.merchantAuditStaffName">
          审核授权人：{{ insideInfo.merchantAuditStaffName }}-{{
            insideInfo.merchantAuditStaffMobile
          }}-{{
            insideInfo.merchantAuditIsMajor
              ? "创建人"
              : insideInfo.merchantAuditStaffType == "01"
              ? "子账号"
              : "报价师"
          }}
        </li>
        <li v-if="insideInfo.auditTime">
          审核授权时间: {{ insideInfo.auditTime }}
        </li>
        <div style="height: 20px"></div>
      </ul>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :width="`${1000}px`"
      title="补拍记录"
      :visible="remakeRecordsShow"
      @close="remakeRecordsShow = false"
    >
      <div
        class="table_list autoshow"
        v-if="
          isOnelineRemake === true &&
          islbfalg !== 'lb' &&
          islbfalg !== 'lb2' &&
          islbfalg != 'fx' &&
          islbfalg != 'fx-lx'
        "
        style="padding-bottom: 20px"
      >
        <el-table
          v-loading="remakeloading"
          key="onelineRemake"
          :data="remakeImgList"
          max-height="700"
          border
          style="width: 100%"
          id="remakeList"
        >
          <el-table-column
            prop="index"
            type="index"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column label="补拍时间" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.remakeTime || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="补拍商家" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.merchantName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请补拍人" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.merchantStaffId"
                >{{ row.merchantStaffName }}-{{ row.merchantStaffPhone }}</span
              >
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="补拍照片" align="center">
            <template slot-scope="{ row }">
              <div class="images-box" v-if="row.img" @click="seeRemakeImg(row)">
                <img :src="row.img" alt="" />
              </div>
              <div v-else>暂无图片</div>
            </template>
          </el-table-column>
          <el-table-column label="补拍备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.remark"
                class="item"
                effect="dark"
                :content="row.remark"
                placement="bottom"
              >
                <span class="quoteNotecss">{{ row.remark }}</span>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="是否报价" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.isQuote ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="新订单重新提交时间" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.newCreateTime">{{ row.newCreateTime }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table_list autoshow" v-else style="padding-bottom: 20px">
        <el-table
          v-loading="remakeloading"
          key="fxRemake"
          :data="remakeImgList"
          max-height="700"
          border
          style="width: 100%"
          id="remakeList"
        >
          <el-table-column
            prop="index"
            type="index"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column label="补拍类型" width="100" align="center">
            <template slot-scope="{ row }">
              <span>{{
                row.remakeType == "01" ? "主动补拍" : "要求补拍"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请补拍时间" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.applyTime || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="补拍商家" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.merchantName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请补拍人" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.applyStaffId"
                >{{ row.applyStaffName }}-{{
                  row.applyIsMajor
                    ? "创建人"
                    : row.applyStaffType == "01"
                    ? "子账号"
                    : "报价师"
                }}</span
              >
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="remakeTime"
            label="完成补拍时间"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="议价信息"
      :visible.sync="expectedShow"
      :close-on-click-modal="false"
      width="800px  "
      @closed="expectedShowClose"
    >
      <div class="expected_info">
        <div class="expected_info_title">首次报价</div>
        <div class="mt-10">
          <span style="color: #0981ff">采货侠取价商家：</span>
          <span style="color: #0981ff; margin-right: 30px"
            >{{ expectedInfo.firstMerchantName || "--" }}（￥{{
              expectedInfo.firstCHXQuotePrice || "--"
            }}）
          </span>

          <span style="color: #ff687b">采货侠成交价：</span>
          <span style="color: #ff687b"
            >￥{{ expectedInfo.firstCHXPrice || "--" }}</span
          >
        </div>
      </div>
      <div class="expected_info">
        <div class="expected_info_title mb-10">接收议价</div>
        <GlobalTable
          ref="GlobalTable"
          myKey="receiveDtos"
          v-loading="dtosloading"
          :isIndex="false"
          :columns="receiveDtosColumns"
          :data="receiveDtosList"
          :isPagination="false"
          :maxHeight="200"
        >
          <el-table-column
            slot="index"
            type="index"
            align="center"
            label="次序"
            width="70"
            :index="
              (index) => {
                return index + 1;
              }
            "
          />
          <el-table-column
            label="期望商家报价"
            slot="expectedQuotePrice"
            align="center"
          >
            <template slot-scope="{ row }">
              ￥{{ row.expectedQuotePrice }}
            </template>
          </el-table-column>
          <el-table-column
            label="采货侠成交价"
            slot="expectedPrice"
            align="center"
          >
            <template slot-scope="{ row }">
              ￥{{ row.expectedPrice }}
            </template>
          </el-table-column>
          <el-table-column
            label="接收议价回收商"
            slot="merchantName"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.merchantName"
                class="item"
                effect="dark"
                :content="row.merchantName"
                placement="bottom"
              >
                <span class="quoteNotecss">{{ row.merchantName }}</span>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            label="议价备注"
            slot="expectedRemark"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.expectedRemark"
                class="item"
                effect="dark"
                :content="row.expectedRemark"
                placement="bottom"
              >
                <span class="quoteNotecss">{{ row.expectedRemark }}</span>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
      <div class="expected_info">
        <div class="expected_info_title mb-10">提交议价</div>
        <GlobalTable
          ref="GlobalTable"
          myKey="submitDtos"
          v-loading="dtosloading"
          :isIndex="false"
          :columns="submitDtosColumns"
          :data="submitDtosList"
          :isPagination="false"
          :maxHeight="200"
        >
          <el-table-column
            slot="index"
            type="index"
            align="center"
            label="次序"
            width="70"
            :index="
              (index) => {
                return index + 1;
              }
            "
          />
          <el-table-column
            label="提交商家报价"
            slot="quotePrice"
            align="center"
          >
            <template slot-scope="{ row }"> ￥{{ row.quotePrice }} </template>
          </el-table-column>
          <el-table-column
            label="采货侠成交价"
            slot="fixExpPrice"
            align="center"
          >
            <template slot-scope="{ row }">
              ￥{{ row.fixExpPrice }}
              <p
                v-if="row.makeUpState == '02'"
                style="color: rgb(255, 104, 123)"
              >
                商家成交价{{ row.chxPriceSub }}元,平台补贴{{
                  row.chxPriceExtra
                }}元
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="议价审批商家"
            slot="merchantName"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.merchantName"
                class="item"
                effect="dark"
                :content="row.merchantName"
                placement="bottom"
              >
                <span class="quoteNotecss">{{ row.merchantName }}</span>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            label="议价审批人"
            slot="merchantStaff"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.merchantStaffName"
                >{{ row.merchantStaffName }}({{
                  row.merchantStaffIsMajor
                    ? "创建人"
                    : row.merchantStaffType == "01"
                    ? "子账号"
                    : "报价师"
                }})-{{ row.merchantStaffPhone }}</span
              >
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="类型" slot="expType" align="center">
            <template slot-scope="{ row }">
              <span>{{
                row.expType == "00"
                  ? "商家议价"
                  : row.expType == "01"
                  ? "系统提交"
                  : row.expType == "02"
                  ? "超时未议价采货侠获取"
                  : "--"
              }}</span>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </el-dialog>

    <el-dialog
      title="重复询价详情"
      :visible.sync="enquiryDetailsShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="enquiryDetailsClose"
    >
      <div class="expected_info">
        <div class="expected_info_title">原订单</div>
        <div class="mt-10">
          <span style="color: #706f7e">原订单：</span>
          <span
            @click="handleClick(chxInfo)"
            style="text-decoration: underline; cursor: pointer; color: #0981ff"
            >{{ chxInfo.orderNo || "--" }}</span
          >
        </div>
        <div class="mt-10">
          <span style="color: #706f7e">原订单采货侠取价商家：</span>
          <span style="color: #333333">{{
            chxInfo.oriMerchantName || "--"
          }}</span>
        </div>
        <div class="mt-10">
          <span style="color: #706f7e">真实报价：</span>
          <span style="color: #333333"
            >￥{{ chxInfo.quotePrice || "--" }}（一根线成交价：￥{{
              chxInfo.chxPrice || "--"
            }}）</span
          >
        </div>
      </div>
      <div class="expected_info">
        <div class="expected_info_title">新订单</div>
        <div class="mt-10">
          <span style="color: #706f7e">新订单报价方式：</span>
          <span style="color: red">{{
            chxInfo.quoteType == "all"
              ? "全部回收商报价"
              : chxInfo.quoteType == "sys"
              ? "系统自动报价"
              : "指定回收商报价"
          }}</span>
        </div>
        <div class="mt-10" v-if="chxInfo.quoteType != 'all'">
          <span style="color: #706f7e">新订单采货侠取价商家：</span>
          <span style="color: #333333">{{
            chxInfo.newMerchantName || "--"
          }}</span>
        </div>
        <div class="mt-10" v-if="chxInfo.quoteType != 'all'">
          <span style="color: #706f7e">商家报价：</span>
          <span style="color: #333333"
            >￥{{ chxInfo.newQuotePrice || "--" }}（一根线成交价：￥{{
              chxInfo.newChxPrice || "--"
            }}）</span
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="makeUpTitle"
      :visible.sync="makeUpDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="enable-tip" v-if="makeUpTitle == '报价提示'">
        <div>
          说明：订单未推送报价给采货侠，平台可报价；若订单已向采货侠推送报价，则不可报价。
        </div>
        <div class="tip-sure">是否确认报价该订单？</div>
      </div>
      <div class="enable-tip" v-else>
        <div>
          说明：订单未推送报价给采货侠，可撤销报价；若订单已向采货侠推送报价，则不可撤销报价。
        </div>
        <div class="tip-sure">是否确认撤销报价？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="makeUpDialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="imakeUpBtnLoading"
          @click.native="imakeUpSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="平台加价信息"
      :visible.sync="platformMarkUpShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="platformMarkUpClose"
    >
      <div class="platform_markup">
        <div class="flex flex-jb">
          <div class="flex">
            <span>推送采货侠价格：￥{{ makeUpInfo.quotePrice }}</span>
            <span style="color: red"
              >（加价￥{{ makeUpInfo.finalMakeUpPrice || "0" }}）</span
            >
          </div>
          <div class="flex">
            <span>加价实际报价：¥{{ makeUpInfo.finalPrice }}</span>
          </div>
        </div>
        <div class="grey_box">
          <div>
            取价时平台最高用户成交价：¥{{ makeUpInfo.targetMaxQuotePrice }}
          </div>
          <div>
            加价使用规则：加价比例：{{ makeUpInfo.makeUpRate }}%，加价上限：{{
              makeUpInfo.makeUpLimit
            }}元
          </div>
        </div>
        <div style="color: #333; margin-top: 10px">
          <span>操作人：</span>
          <span v-if="makeUpInfo.makeUpName && makeUpInfo.makeUpMobile"
            >{{ makeUpInfo.makeUpName }}-{{ makeUpInfo.makeUpMobile }}</span
          >
          <span v-else>自动加价</span>
        </div>
        <div style="color: #333; margin-top: 10px">
          <span>操作时间：</span>
          <span>{{ makeUpInfo.makeUpTime }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="platformMarkUpShow = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-image-viewer
      :zIndex="99999"
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
    <!-- 补贴加价弹出框 -->
    <el-dialog
      class="remarks_dialog"
      title="补贴加价"
      :visible.sync="aSubsidyShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="SubsidyClosed"
    >
      <div class="call_accounts_bill">
        <h3 class="lv_fc">报价信息</h3>
        <div class="call_accounts_info">
          <div class="call_accounts_result">
            <p>
              {{ marKudata.phoneName || "--" }}
              <span v-if="marKudata.phoneStorage"
                >（{{ marKudata.phoneStorage }}）</span
              >，
              <span v-if="marKudata.pushQuote">
                当前推送报价：{{ marKudata.pushQuote }}（{{
                  marKudata.merchantName || "--"
                }}），
                <span v-if="marKudata.expPriceLimit">
                  平台支持补贴加价：<span style="color: rgb(255, 104, 123)"
                    >1~{{ marKudata.expPriceLimit }}元</span
                  >，超出需上传收款凭证.
                </span>
                <span v-else> 该价位段未设置补贴，不可申请补贴。 </span>
              </span>
              <span v-else> 暂未推送报价，不可申请补贴 </span>
            </p>
          </div>
        </div>
        <h3 class="lv_fc">加价金额</h3>
        <div>
          <div class="command" style="margin-top: 20px">
            <el-input
              size="small"
              type="number"
              :disabled="flagSave"
              v-model="callAccountsProps.makeUpPrice"
              placeholder="请输入加价金额"
            ></el-input>
            <el-button
              size="small"
              :key="99"
              type="success"
              v-if="!flagSave"
              @click="saveAddprice"
              >保存价格</el-button
            >
            <el-button
              size="small"
              :key="88"
              type="primary"
              v-else
              @click="updateprice"
              >修改价格</el-button
            >
          </div>
          <p
            style="color: #0981ff"
            v-if="
              callAccountsProps.makeUpPrice <= marKudata.expPriceLimit &&
              flagSave
            "
          >
            加价金额在限额内，无需上传凭证
          </p>
          <p
            style="color: rgb(255, 104, 123)"
            v-if="
              callAccountsProps.makeUpPrice > marKudata.expPriceLimit &&
              flagSave
            "
          >
            加价金额超出限额内，请上传收款凭证
          </p>
        </div>
        <div
          class="call_accounts_check"
          v-if="
            callAccountsProps.makeUpPrice > marKudata.expPriceLimit && flagSave
          "
        >
          <p style="margin-bottom: 10px">
            <span style="color: red">*</span>超额加价原因：
          </p>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入超额加价原因"
            v-model="callAccountsProps.thanMakeUp"
            maxlength="100"
            show-word-limit
          />
        </div>
        <div
          class="call_accounts_check"
          v-if="
            callAccountsProps.makeUpPrice > marKudata.expPriceLimit && flagSave
          "
        >
          <p style="margin-bottom: 10px">
            <span style="color: red">*</span>收款凭证
          </p>
          <ElImgUploadGroup
            :length="1"
            :exist-img-list="callAccountsProps.thanMakeUpVoucher"
            @handleChange="handleUploadGroupChange($event)"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="SubsidyClosed">取消</el-button>
        <el-button
          type="primary"
          :loading="butLoading"
          @click.native="SubsidySure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看成交明细  -->
    <QuoteDetailsDia ref="quoteDetailsDia" />
    <LimitPriceDia ref="limitPriceDia" />
    <!-- 旧机功能情况 -->
    <el-dialog
      v-bind="imeiTestProps"
      :before-close="() => (imeiTestProps.show = false)"
      :visible.sync="imeiTestProps.show"
    >
      <div v-loading="imeiTestProps.isLoadding" class="function_box">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo
            :externalorderType="externalorderType"
            :datainfo="detailData"
          />
        </div>
        <div>
          <!-- 估价单验机报告 -->
          <div class="test-box">
            <div
              class="test-result"
              v-if="detailData.functionView.errorList.length"
            >
              <Title
                :title="
                  '异常项（共' +
                  detailData.functionView.errorList.length +
                  '项）'
                "
              />
              <!-- 旧机无系统检测 -->
              <div
                class="mb10"
                v-if="
                  detailData.unableDetectionCause &&
                  detailData.detectionType == '01'
                "
              >
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  detailData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div
                class="mb10"
                style="display: flex; justify-content: space-between"
                v-if="detailData.detectionType == '02'"
              >
                <span class="fs16"
                  >检测机型：{{ detailData.detectionModel || "--" }}</span
                >
                <span class="fs16"
                  >录入串号：{{ detailData.imei || "--" }}</span
                >
              </div>
              <TestTable
                :externalorderType="externalorderType"
                :table-data="detailData.functionView.errorList"
              ></TestTable>
            </div>
            <div
              class="test-result"
              style="margin-top: 20px"
              v-if="detailData.functionView.normalList.length"
            >
              <Title
                :title="
                  '正常项（共' +
                  detailData.functionView.normalList.length +
                  '项）'
                "
              />
              <TestTable
                :externalorderType="externalorderType"
                :table-data="detailData.functionView.normalList"
              ></TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0">
          <Title title="店员备注" />
          <Remark
            :remark="detailData.remark"
            :againPhotoRemark="detailData.againPhotoRemark"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button
          v-if="imeiTestProps.type === '01'"
          @click="imeiTestProps.show = false"
          plain
          type="primary"
          >关闭
        </el-button>
        <div v-else>
          <el-button @click="imeiTestProps.show = false">取 消</el-button>
          <el-button type="primary" @click="imeiTestProps.show = false"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 配置列 -->
    <el-dialog title="配置列数据" :visible.sync="configShow" width="20%">
      <GlobalTable
        ref="multipleTable"
        :columns="configList"
        :data="configData"
        :maxHeight="500"
        :isPagination="false"
        @handleSelect="handleSelectionChange"
      >
        <el-table-column
          :reserve-selection="true"
          type="selection"
          slot="checkColumn"
          align="center"
        >
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelConfig">取 消</el-button>
        <el-button type="primary" @click="handleConfig">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 确认成交-->
    <DialogAction
      v-bind="dealProp"
      @close="closecode"
      @codeChange="setcode"
      @config="dealConfig"
    >
      <template v-slot:from>
        <el-form style="width: 80%">
          <el-form-item
            label="绑定条码:"
            :label-width="dealProp.codeLabelWidth"
          >
            <el-input
              placeholder="请输入外部订单绑定条码，最多15位"
              maxlength="15"
              v-model="dealProp.barCode"
              clearable
            />
          </el-form-item>
        </el-form>
      </template>
    </DialogAction>
    <!-- 外部商家报价 -->
    <el-dialog title="外部商家报价" :visible.sync="quotePriceShow" width="60%">
      <div class="command">
        <div>
          平台最高显示价：{{ outMerchant.maxQuoteSecretPrice }}
          <span v-if="outMerchant.maxMerchantName"
            >（{{ outMerchant.maxMerchantName }}）</span
          >
        </div>
        <div style="margin-left: 50px">
          外部商家添加门槛：显示价需比平台<span style="color: #ff687b"
            >高{{ outMerchant.quoteCondition || 0 }}元</span
          >
        </div>
      </div>
      <GlobalTable
        :loading="quoteOperateLoading"
        ref="quotePriceTable"
        :columns="quotePriceList"
        :data="quotePriceData"
        :maxHeight="500"
        :isPagination="false"
      >
        <el-table-column
          label="商户名称"
          slot="recycleMerchantName"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              v-if="row.recycleMerchantName != ''"
              class="item"
              effect="dark"
              :content="row.recycleMerchantName"
              placement="top"
            >
              <div class="quoteNotecss">{{ row.recycleMerchantName }}</div>
            </el-tooltip>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          label="原始报价"
          slot="originalQuotePrice"
          align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="row.originalQuotePrice">
              {{ row.originalQuotePrice }}
              <span v-if="row.commission"
                >（含{{ row.commission }}元手续费）</span
              >
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="报价金额" slot="quotePrice" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.quotePrice || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="门店显示价"
          slot="quoteSecretPrice"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.quoteSecretPrice || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="报价时间" slot="quoteTime" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.quoteTime || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="报价单状态" slot="state" align="center">
          <template slot-scope="{ row }">
            <!-- 
                第一行：已报价 （state = 00-2，01），（state = 00-0，00-1）拒绝报价（isRejectQuote=true） 
                第二行：已添加竞价（isJoinQuote = true），
                        报价不足未添加 （isJoinQuote = false，isUpCnd = false），
                        待补拍 （reApplyState=00），
            -->
            <div>
              {{
                row.isRejectQuote == true
                  ? "拒绝报价"
                  : row.state == "00-2" || row.state == "01"
                  ? "已报价"
                  : row.state == "00-0" || row.state == "00-1"
                  ? "已邀请未报价"
                  : row.state == "10"
                  ? "交易取消"
                  : "--"
              }}
            </div>
            <!-- 已邀请未报价 -->
            <div v-if="row.state == '00-0' || row.state == '00-1'">
              <span v-if="row.reApplyState == '00'">(待补拍)</span>
              <span v-if="row.reApplyState == '01'">(已补拍)</span>
            </div>
            <!-- 已报价 -->
            <div v-if="row.state == '00-2' || row.state == '01'">
              <span v-if="row.reApplyState == '00'">(待补拍)</span>
              <span v-if="row.isJoinQuote == true">(已添加竞价)</span>
              <span v-if="row.isJoinQuote == false && row.isUpCnd == false"
                >(报价不足未添加)</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="付款状态" slot="outPayState" align="center">
          <template slot-scope="{ row }">
            <div
              v-if="
                row.outPayRefundState != '00' && row.outPayRefundState != null
              "
            >
              {{
                row.outPayState == "01"
                  ? "退款中"
                  : row.outPayState == "02"
                  ? "退款完成"
                  : row.outPayState == "03"
                  ? "退款失败"
                  : "--"
              }}
            </div>
            <div v-else-if="row.outPayRefundState == '00'">
              {{
                row.outPayState == "00"
                  ? "未发起"
                  : row.outPayState == "01"
                  ? "付款中"
                  : row.outPayState == "02"
                  ? "已付款"
                  : "--"
              }}
            </div>
            <div v-else>--</div>
            <div
              class="text-down"
              v-if="row.outPayState || row.outPayRefundState"
              @click="viewPayer(row)"
            >
              查看付款信息
            </div>
          </template>
        </el-table-column>
        <el-table-column label="付款方" slot="outPayChannel" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.outPayChannel == "Store"
                ? "店员付款"
                : row.outPayChannel == "Merchant"
                ? "回收商付款"
                : "--"
            }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quotePriceShow = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 外部商家报价 -->
    <el-dialog title="付款信息" :visible.sync="paymentShow" width="30%">
      <div class="detail-price-box">
        <div>
          <div class="outPay">
            <span class="outPay_title">付款信息：</span>
            <div class="outPay_tag">
              {{ payInfo.outPayChannel == "Store" ? "店员付款" : "回收商付款" }}
            </div>
          </div>
          <div class="outPay_info">
            <div>
              付款人：{{ payInfo.outPayStaffName }} -
              {{ payInfo.outPayStaffMobile }}
              <span v-if="payInfo.outPayStaffType"
                >（{{ payInfo.outPayStaffType }}）</span
              >
            </div>
            <div>付款时间：{{ payInfo.outPayTime || "--" }}</div>
            <div>微信交易单号：{{ payInfo.outPayNo }}</div>
          </div>
        </div>
        <div
          class="mt-20"
          v-if="payInfo.outPayRefundTime || payInfo.outPayRefundNo"
        >
          <div class="outPay">
            <span class="outPay_title">退款信息：</span>
          </div>
          <div class="outPay_info1">
            <!-- <div>退回账户：{{ payInfo.test000 }}</div> -->
            <div>退款时间：{{ payInfo.outPayRefundTime }}</div>
            <div>微信退款单号：{{ payInfo.outPayRefundNo }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paymentShow = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看收货地址"
      width="500px"
      :visible.sync="distributeShow"
      :close-on-click-modal="false"
      @close="distributeShow = false"
    >
      <div class="address-box" v-if="addressObj">
        <div class="mb-10" style="color: #0981ff; font-size: 16px">
          收件人信息
        </div>
        <div style="margin-bottom: 5px">
          {{ addressObj.contactName }}-{{ addressObj.contactPhone }}
        </div>
        <div>
          {{ addressObj.merchantAddress
          }}{{ addressObj.houseAddress ? "-" + addressObj.houseAddress : "" }}
        </div>
      </div>
      <div class="address-box" v-else>暂未绑定收货地址</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="distributeShow = false">取消</el-button>
        <el-button type="primary" @click.native="distributeShow = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看预估价-->
    <el-dialog
      title="查看预估价"
      :visible.sync="futurePriceShow"
      :close-on-click-modal="false"
      width="500px"
    >
      <p style='margin-bottom:10px'>分毫靓机价：{{futurePriceData.fhNicePrice}}</p>
      <p>预估价所属配置：<span style="color:red">{{futurePriceData.prePriceConfig}}</span><span>（{{futurePriceData.niceLower}}＜区间≤{{futurePriceData.niceUpper}}，系数{{futurePriceData.prePriceFactor}}）</span></p>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="futurePriceShow=false">关闭</el-button>
      </span>
    </el-dialog>
    <InvisibleIDdetectionDia
      :selectedStoreList="selectedStoreList"
      :hideIdCheckState="hideIdCheckState"
      @closedetection="closedetection"
      :show="detectionvisible"
    ></InvisibleIDdetectionDia>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImgUploadGroup from "../../components/global/components/elements/upload-group-element.vue";
import moment from "moment";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import DialogAction from "@/components/common/DialogAction";
import InvisibleIDdetectionDia from "./componets/InvisibleIDdetectionDia.vue";
import {
  getHealthCode,
  getOutNameMake,
  openAction,
  setValue,
} from "@/utils/common";
import { imeiTestProps } from "@/views/RecallOrder/initState";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import Title from "./componets/Title.vue";
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
// import TestUse from "@/views/RecallOrder/componets/TestUse.vue";
import Remark from "@/views/RecallOrder/componets/Remark.vue";
import QuoteDetailsDia from "./componets/QuoteDetailsDia.vue";
import LimitPriceDia from "./componets/LimitPriceDia.vue";
import QuoteLimitSettingDia from "./componets/QuoteLimitSettingDia.vue";

let checkNum = (rule, value, callback) => {
  if (value == 0 || value > 100) {
    callback(new Error("加价比例必须大于0小于等于100"));
  } else {
    callback();
  }
};
export default {
  name: "OrderTable",
  components: {
    // TestUse,
    TestTable,
    TestDeviceInfo,
    DialogAction,
    ElImageViewer,
    Title,
    Remark,
    ElImgUploadGroup,
    QuoteDetailsDia,
    LimitPriceDia,
    QuoteLimitSettingDia,
    InvisibleIDdetectionDia,
  },
  data() {
    return {
      detectionvisible: false,
      selectedStoreList: [],
      hideIdCheckState: "",
      distributeShow: false,
      addressObj: {},
      deadline4: 0,
      quoteOperateLoading: false,
      paymentShow: false,
      payInfo: { outPayStaffMobile: "" },
      quotePriceShow: false,
      quotePriceList: [
        { slotName: "recycleMerchantName" }, //商户名称
        // { label: "商户名称", prop: "recycleMerchantName" },
        { label: "创建人", prop: "createStaffName" },
        { label: "联系电话", prop: "createStaffPhone" },
        { slotName: "originalQuotePrice" },
        // { label: "门店显示价", prop: "quoteSecretPrice" },
        // { label: "报价时间", prop: "quoteTime" },
        { slotName: "quotePrice" }, //报价金额
        { slotName: "quoteSecretPrice" }, //门店显示价
        { slotName: "quoteTime" }, // 报价时间
        { slotName: "state" }, //报价单状态
        { slotName: "outPayState" }, //付款状态
        { slotName: "outPayChannel" }, //付款方
      ],
      outMerchant: {
        maxMerchantName: "",
        maxQuoteSecretPrice: 0,
        quoteCondition: 0,
      },
      quotePriceData: [],
      quotePriceLoading: false,
      butLoading: false,
      flagSave: false,
      callAccountsProps: {
        makeUpPrice: "",
        orderNo: "",
        thanMakeUp: "",
        thanMakeUpVoucher: [],
      },
      aSubsidyShow: false,
      configRefresh: true,
      middleColumns: [],
      storeLoading: false,
      configShow: false,
      configList: [
        { label: "列名", prop: "label" },
        { slotName: "checkColumn" }, //显示
      ],
      configData: [
        {
          prop: "recycleMerchantName",
          label: "报价商家",
        },
        {
          prop: "originalQuotePrice",
          label: "报价金额（含差异基金）",
        },
        {
          prop: "quoteSecretPrice",
          label: "用户成交价",
        },
        {
          prop: "exclusiveTime",
          label: "抢单时间",
        },
        {
          prop: "quoteTime",
          label: "最新报价时间",
        },
        {
          prop: "staffName",
          label: "报价人",
        },
        {
          prop: "quoteNum",
          label: "报价次数",
        },
        {
          prop: "state",
          label: "报价状态",
        },
        {
          prop: "isNiceDevice",
          label: "是否标记靓机",
        },
        {
          prop: "quoteNote",
          label: "报价备注",
        },
        {
          prop: "quotePrice",
          label: "报价金额",
        },
        {
          prop: "diffFundPrice",
          label: "差异基金",
        },
        {
          prop: "quoteChannelServerFee",
          label: "渠道服务费",
        },
        {
          prop: "commission",
          label: "外部预扣手续费",
        },
        {
          prop: "limitExclusiveLength",
          label: "限时报价时长",
        },
      ],
      storeColumns: [
        { slotName: "recycleMerchantName" }, //报价商家
        // { label: "报价金额（含差异基金）", prop: "originalQuotePrice" }, //报价金额
        { slotName: "originalQuotePrice" }, //报价金额
        // { label: "报价金额", prop: "quotePrice" }, //报价金额
        { slotName: "quotePrice" }, //报价金额
        { label: "用户成交价", prop: "quoteSecretPrice" }, //用户成交价
        { label: "抢单时间", prop: "exclusiveTime" }, //抢单时间
        { label: "最新报价时间", prop: "quoteTime" }, //最新报价时间
        { slotName: "staffName" }, //报价人
        { slotName: "quoteNum" }, //报价次数
        { slotName: "state" }, //报价状态
        { slotName: "isNiceDevice" }, //是否标记靓机
        { slotName: "quoteNote" }, //报价备注
        // { label: "差异基金", prop: "diffFundPrice" }, //差异基金
        { slotName: "diffFundPrice" }, //差异基金
        { slotName: "quoteChannelServerFee" }, //渠道服务费
        { slotName: "commission" }, //外部预扣手续费
        { slotName: "limitExclusiveLength" }, //限时报价
      ],
      configColumnsArr: [],
      // 撤销收货
      cancelReceiveShow: false,
      cancelCommandTex: "", //动态口令
      cancelResonTxt: "", //撤销原因
      orderNo: "",
      getRowKeys(row) {
        return row.index;
      },
      //压价
      editPriceShow: false,
      yajiaShow: false,
      yaCommand: "",
      addPriceShow: false,
      editPricerules: {
        limitationRate: [
          { required: true, message: "请输入上浮报价比例", trigger: "blur" },
          { type: "number", validator: checkNum, trigger: "blur" },
        ],
        limitationLimit: [
          {
            required: true,
            message: "请输入上浮报价上限",
            trigger: "blur",
          },
        ],
      },
      editPriceForm: {
        lower: "",
        upper: "",
        limitationRate: "",
        limitationLimit: "",
      },
      regionList: [],
      regionColumns: [
        { slotName: "region" },
        { slotName: "limitationRate" },
        { slotName: "limitationLimit" },
        { slotName: "operation" },
      ],
      externalorderType: "", //订单类型
      priceAllList: [
        {
          name: "差异基金",
          id: "1",
          num: 0,
        },
        {
          name: "渠道服务费",
          id: "2",
          num: 0,
        },
        {
          name: "公司返利",
          id: "3",
          num: 0,
        },
        {
          name: "店长红包",
          id: "4",
          num: 0,
        },
        {
          name: "店员红包",
          id: "5",
          num: 0,
        },
        {
          name: "计算推送报价",
          id: "6",
          num: 0,
        },
      ],
      switchid: "1",
      notekeepList: [
        {
          name: "首次取价",
          id: "1",
          type: "",
        },
        {
          name: "第1次补拍",
          id: "2",
          type: "",
        },
        {
          name: "第2次补拍",
          id: "3",
          type: "",
        },
        {
          name: "第3次补拍",
          id: "4",
          type: "",
        },
      ],
      copykeepList: [],
      outType: "",
      Quotationremarks: "",
      Pushprice: "",
      Closingoffer: false,
      pricePushshow: false, //推送报价弹窗
      Manualpushshow: false, //手动推送报价弹窗
      // 功能情况接口
      detailData: {
        functionView: {
          normalList: [],
          errorList: [],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: [],
      },
      imeiTestProps,
      // 成交明细
      dealDetailProps: {
        show: false,
        data: {},
        // true代表已确认，fasle代表未确认
        type: false,
        title: "报价明细",
      },
      // 成交弹窗
      dealProp: {
        title: "操作提示",
        isTip: true,
        tipText: "是否确认成交该订单？",
        isCode: true,
        headerMessage:
          "说明：仅限外部订单可操作，确认成交自动调用外部绑码接口，操作后该订单变为已绑码",
        show: false,
        code: "",
        // 条码
        barCode: "",
        // 当前行数据
        currentData: {},
        codeLabel: "动态口令:",
        codeLabelWidth: "80px",
      },
      baoPricestate: "", //订单状态
      recorderShow: false, //恢复记录弹窗
      recorderList: [],
      codeShow: false, //导出Excel谷歌口令弹框
      code: "", //导出Excel谷歌口令
      putBtnLoading: false,
      codeLoading: false, //导出订单
      putDialogShow: false,
      recollectionDialogShow: false,
      plusPriceIF: false,
      expectedShow: false,
      /*********** 新增 **********/
      imgShow: false,
      imgShowOnly: true,
      DialogImgList: [[], []],
      imgShowIndex: 0,
      imgIndex: 0,
      storeShow: false,
      viewrecords: false,
      remakeRecordsShow: false,
      storeList: [],
      pcaddPric: null,
      remakeloading: false,
      enquiryDetailsShow: false,
      /*********** 新增 **********/
      pages: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      itemArr: [],
      itemTable: [],
      orderTableList: [],
      loading: false,
      dialogVisibleOrder: false,
      dialogTitle: "",
      dialogcancel: {
        remark: "",
      },
      getUpdatedQuoteLog: [],
      pushPricedatalist: [], //推送记录
      remakeQuoteLog: [],
      remakeImgList: [],
      subInfoShow: false,
      insideInfoShow: false,
      insideInfo: {},
      subInfo: {
        img: "",
        staffName: "",
        nickName: "",
      },
      expectedInfo: {},
      chxInfo: {},
      receiveDtosList: [],
      submitDtosList: [],
      dtosloading: false,
      isOnelineRemake: "",
      islbfalg: "",
      receiveDtosColumns: [
        { slotName: "index" },
        { slotName: "expectedQuotePrice" },
        { slotName: "expectedPrice" },
        { slotName: "merchantName" },
        { label: "接收议价时间", prop: "applyExpectedTime" },
        { slotName: "expectedRemark" },
      ],
      submitDtosColumns: [
        { slotName: "index" },
        { slotName: "quotePrice" },
        { slotName: "fixExpPrice" },
        { slotName: "merchantName" },
        { label: "提交议价时间", prop: "expectedAckQuoterTime" },
        { slotName: "merchantStaff" },
        { slotName: "expType" },
      ],
      isopenSubsidy: "",
      showViewer: false,
      imgList: [],
      makeUpTitle: "",
      makeUpDialogVisible: false,
      imakeUpBtnLoading: false,
      platformMarkUpShow: false,
      makeUpInfo: {},
      command: "",
      reImgQuote: {},
      firstQuote: [],
      allQuote: {},
      operationdatalist: {},
      handsRecordershow: false, //手动推送操作记录弹窗
      priceorderNo: "",
      calculationamount: {
        compute: {},
      },
      issave: false, //是否保存
      copyallList: {
        copydataAll: [],
      },
      marKudata: {},
      futurePriceShow: false,
      futurePriceData: {},
    };
  },
  created() {
    this.getchxExpconfig();
    let startTime = moment().day(moment().day()).format("YYYY-MM-DD 00:00:00");
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.ClearSeachParams();
    const baseRequest = {
      endTime: this.$route.query.orderNo ? "" : moment(endTime).format("x"),
      startTime: this.$route.query.orderNo ? "" : moment(startTime).format("x"),
      // 修改询价商家为多选
      // companyId: Number(this.$route.query.companyId) || "",
      companyId: this.$route.query.companyId
        ? [Number(this.$route.query.companyId)]
        : [],
      merchantId: Number(this.$route.query.merchantId) || "",
      imei: this.$route.query.imei || "",
      orderNo: this.$route.query.orderNo || "",
    };
    this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);
    // this.handleCurrentChange();
    this.middleColumns = [...this.storeColumns];
  },
  computed: {
    SeachParams() {
      console.log(this.$store.state.tagsView.SeachParams);
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newval) {
      console.log(newval, "SeachParamsSeachParams");
      this.handleCurrentChange();
    },
  },
  methods: {
    //查看预估价
    seafuturePrices(row) {
      _api.seaViewpreprice({orderNo:row.orderNo}).then(res=>{
        this.futurePriceShow = true;
        this.futurePriceData=res.data
      })
    },
    closedetection() {
      console.log("关闭ID测试");
      this.detectionvisible = false;
    },
    async onQuotePrice() {
      await this.handleOutConfigColumns();
      this.quotePriceShow = true;
    },
    seaAddress(row) {
      console.log(row);
      _api.getReceiveAddress({ orderNo: row.orderNo }).then((res) => {
        console.log(res);
        this.addressObj = res.data;
        this.distributeShow = true;
      });
    },
    closecode() {
      this.dealProp.show = false;
    },
    setcode(val) {
      this.dealProp.code = val;
    },
    //倒计时结束
    hilarity() {
      _api.getPushQuoteRecord({ orderNo: this.priceorderNo }).then((res) => {
        if (res.code == 1) {
          this.allQuote = JSON.parse(JSON.stringify(res.data));
          this.firstQuote = res.data.firstQuote || [];
          this.reImgQuote = res.data.reImgQuote || {};
          //默认首次取价
          this.pushPricedatalist = this.firstQuote;
          this.Closingoffer = false;
        }
      });
    },
    // 如果是门店商付款，staffType=01并且major=true是创建人，staffType=01并且major=false是子账号，staffType=02是总监，staffType=03是店长，staffType=04是店员
    // 如果是回收商付款，staffType=01并且major=true是创建人，staffType=01并且major=false是子账号，staffType=02是报价师
    viewPayer(row) {
      this.payInfo = {
        outPayChannel: row.outPayChannel, //付款信息  账户类型
        outPayStaffType:
          row.outPayChannel == "Store"
            ? row.outPayStaffIsMajor && row.outPayStaffType == "01"
              ? "创建人"
              : !row.outPayStaffIsMajor && row.outPayStaffType == "01"
              ? "子账号"
              : row.outPayStaffType == "02"
              ? "总监"
              : row.outPayStaffType == "03"
              ? "店长"
              : row.outPayStaffType == "04"
              ? "店员"
              : ""
            : row.outPayStaffIsMajor && row.outPayStaffType == "01"
            ? "创建人"
            : !row.outPayStaffIsMajor && row.outPayStaffType == "01"
            ? "子账号"
            : row.outPayStaffType == "02"
            ? "报价师"
            : "",
        outPayStaffName: row.outPayStaffName,
        outPayStaffMobile: row.outPayStaffMobile,
        outPayTime: row.outPayTime,
        outPayNo: row.outPayNo,
        // test000 : row.test000,
        outPayRefundTime: row.outPayRefundTime,
        outPayRefundNo: row.outPayRefundNo,
      };
      this.paymentShow = true;
    },
    getchxExpconfig() {
      _api.chxExpconfig().then((res) => {
        this.isopenSubsidy = res.data.isOpen;
      });
    },
    updateprice() {
      this.flagSave = false;
    },
    saveAddprice() {
      if (!this.callAccountsProps.makeUpPrice) {
        return this.$message.error("请输入加价金额！");
      }
      this.flagSave = true;
    },
    handleUploadGroupChange(list) {
      console.log(list);
    },
    markupPrice(orderNo) {
      this.callAccountsProps.orderNo = orderNo;
      _api.getMakeUpExpInfo({ orderNo }).then((res) => {
        this.marKudata = res.data;
      });
      this.flagSave = false;
      this.callAccountsProps.makeUpPrice = "";
      this.callAccountsProps.thanMakeUp = "";
      this.callAccountsProps.thanMakeUpVoucher = [];
      this.aSubsidyShow = true;
    },
    //补贴加价
    SubsidySure() {
      if (!this.marKudata.pushQuote) {
        return this.$message.error("暂未推送报价，不可申请补贴！");
      }
      if (this.marKudata.pushQuote && !this.marKudata.expPriceLimit) {
        return this.$message.error(" 该价位段未设置补贴，不可申请补贴！");
      }
      if (!this.flagSave) {
        return this.$message.error("请先输入或保存加价金额！");
      }
      if (this.callAccountsProps.makeUpPrice > this.marKudata.expPriceLimit) {
        if (!this.callAccountsProps.thanMakeUp) {
          return this.$message.error("请输入超额加价原因！");
        }
        if (this.callAccountsProps.thanMakeUpVoucher.length === 0) {
          return this.$message.error("请上传收款凭证！");
        }
      } else {
        this.callAccountsProps.thanMakeUpVoucher = [];
        this.callAccountsProps.thanMakeUp = "";
      }
      let mahuParams = {
        makeUpPrice: this.callAccountsProps.makeUpPrice,
        orderNo: this.callAccountsProps.orderNo,
        thanMakeUp: this.callAccountsProps.thanMakeUp,
        thanMakeUpVoucher: this.callAccountsProps.thanMakeUpVoucher[0] || "",
      };
      _api.submitMakeUp(mahuParams).then((res) => {
        if (res.code == 1) {
          this.aSubsidyShow = false;
          this.$message({
            message: res.msg || "加价成功！",
            type: "success",
          });
          this.handleCurrentChange();
        } else {
          this.$message.error(res.msg || "操作失败！");
        }
      });
      console.log(mahuParams);
    },
    //补贴加价关闭
    SubsidyClosed() {
      this.aSubsidyShow = false;
    },
    // 外部商家报价
    handleOutConfigColumns() {
      this.quoteOperateLoading = true;
      const params = {
        orderNo: this.orderNo,
      };
      _api.getListQuoteByOrderNoOut(params).then((res) => {
        if (res.code === 1) {
          this.quotePriceData = res.data.list;
          (this.outMerchant = {
            maxMerchantName: res.data.maxMerchantName,
            maxQuoteSecretPrice: res.data.maxQuoteSecretPrice,
            quoteCondition: res.data.quoteCondition,
          }),
            (this.quoteOperateLoading = false);
        }
      });
    },
    // 配置列按钮
    handleConfigColumns() {
      // this.toggleSelection()
      // 配置列数据回显
      _api.findColumns({ tableName: "参与报价回收商" }).then((res) => {
        res.data.forEach((item) => {
          this.$nextTick(() => {
            this.configData.find((obj) => {
              if (obj.prop == item.columnName) {
                this.$refs.multipleTable.$refs.tableGroup.toggleRowSelection(
                  obj,
                  true
                );
              }
            });
          });
        });
      });
      this.configShow = true;
    },
    // 列表信息回显
    toggleSelection() {
      this.configRefresh = false;
      _api.findColumns({ tableName: "参与报价回收商" }).then((res) => {
        this.storeColumns = this.middleColumns.filter((item) => {
          return res.data.some(
            (obj) =>
              obj.columnName === item.prop || obj.columnName === item.slotName
          );
        });
        // 解决问题：点击配置数据时，默认选项，其他什么都不选，直接确定而产生的数据为空的bug
        this.configColumnsArr = this.configData.filter((item) => {
          return res.data.some((obj) => obj.columnName === item.prop);
        });
        this.configRefresh = true;
      });
    },
    // 重置配置列
    cancelConfig() {
      this.$refs.multipleTable.clearSelection();
      this.configColumnsArr = [];
    },
    // 确定
    handleConfig() {
      const transformedArr = this.configColumnsArr.map((obj) => {
        return {
          columnName: obj.prop, // 将prop属性值赋给columnName属性
        };
      });
      _api
        .configColumns({ tableName: "参与报价回收商", columns: transformedArr })
        .then((res) => {
          console.log("配置", res);
          if (res.code == 1) {
            this.toggleSelection();
            this.configShow = false;
          }
        });
    },
    handleSelectionChange(row) {
      this.configColumnsArr = [...row];
    },
    // 配置列end
    // 撤销收货***********
    cancelReceive(row) {
      console.log(row, "7777777777777");
      (this.cancelReceiveShow = true), (this.orderNo = row.orderNo);
    },
    cancelReceiveClose() {
      this.cancelReceiveShow = false;
      this.cancelCommandTex = "";
      this.cancelResonTxt = "";
    },
    cancelReceiveSure() {
      if (!this.cancelResonTxt) {
        this.$message.error("请输入撤销收货原因！");
        return;
      }
      _api
        .auditCancelReceipt({
          auditRemark: this.cancelResonTxt,
          command: this.cancelCommandTex,
          orderNo: this.orderNo,
        })
        .then((res) => {
          if (res.code == 1) {
            this.cancelReceiveShow = false;
            this.cancelCommandTex = "";
            this.cancelResonTxt = "";
            this.handleCurrentChange();
          }
        });
    },
    // *********************
    openAction,
    // 内部回收
    switchInsideBiz(row) {
      console.log(row);
    },
    handleSwitchUsed(row) {
      console.log(row);
    },
    //压价
    limitInput(value, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.editPriceForm[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      // this.editPriceForm[name] =value.replace(/[^0-9]/g,'')
      this.editPriceForm[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
      if (this.editPriceForm[name] >= 100) {
        this.editPriceForm[name] = "99";
      }
    },
    limitationLimitChange(v) {
      if (isNaN(Number(v))) {
        return (this.editPriceForm.limitationLimit = null);
      }
      if (parseInt(v) < 0) {
        return (this.editPriceForm.limitationLimit = null);
      }
      if (
        (this.maxlimitationLimit || parseInt(this.maxlimitationLimit) === 0) &&
        Number(v) > this.maxlimitationLimit
      ) {
        return (this.editPriceForm.limitationLimit = this.maxlimitationLimit);
      }
      if (!this.maxlimitationLimit) {
        this.editPriceForm.limitationLimit = v;
      }
    },
    // 失焦
    inputBlur(name) {
      console.log(this.editPriceForm[name]);
      if (
        this.editPriceForm[name].toString().indexOf(".") ==
        this.editPriceForm[name].length - 1
      ) {
        this.editPriceForm[name] = this.editPriceForm[name].replace(".", "");
      }
    },
    //确认压价动态口令
    yaPricesure() {
      if (!this.yaCommand) {
        this.$message.error("请输入动态口令！");
        return;
      }
      this.copyallList.copydataAll = this.regionList;
      let arrParams = JSON.parse(JSON.stringify(this.copyallList)).copydataAll;
      arrParams.forEach((item) => {
        if (item.isBan) {
          item.isBan = "01";
        } else {
          item.isBan = "02";
        }
        if (item.isWarn) {
          item.isWarn = "01";
        } else {
          item.isWarn = "02";
        }
      });
      console.log(this.regionList);
      const params = {
        command: this.yaCommand,
        regionList: arrParams,
      };
      _api.savelimitationconfig(params).then((res) => {
        if (res.code == 1) {
          this.addPriceShow = false;
          this.yajiaShow = false;
          this.$message({
            message: "保存成功！",
            type: "success",
          });
        }
      });
    },
    //确认压价
    submitAddPrice() {
      this.yajiaShow = true;
      this.yaCommand = "";
    },
    // 编辑弹框确认
    submitEditPrice() {
      this.$refs["editPriceRulesForm"].validate((valid) => {
        if (valid) {
          if (this.ifedmit) {
            this.regionList.forEach((item) => {
              if (item.id == this.rowId) {
                item.limitationRate = this.editPriceForm.limitationRate;
                item.lower = this.editPriceForm.lower;
                item.upper = this.editPriceForm.upper;
                item.limitationLimit = this.editPriceForm.limitationLimit;
              }
            });
          } else {
            if (!this.editPriceForm.lower || !this.editPriceForm.upper) {
              this.$message.error("请填写靓机价区间！");
              return;
            }
            if (
              this.editPriceForm.lower &&
              this.editPriceForm.upper &&
              Number(this.editPriceForm.upper) <=
                Number(this.editPriceForm.lower)
            ) {
              this.$message.error("最高靓机价必须大于最低靓机价！");
              return;
            }
            this.regionList.push({
              limitationRate: this.editPriceForm.limitationRate,
              lower: this.editPriceForm.lower,
              isBan: true,
              isWarn: true,
              upper: this.editPriceForm.upper,
              id: this.regionList.length + "yj",
              limitationLimit: this.editPriceForm.limitationLimit,
            });
          }
          this.editPriceShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭编辑
    editPriceClosed() {
      this.$refs["editPriceRulesForm"].resetFields();
      this.rowId = "";
      this.editPriceForm = {
        lower: "",
        upper: "",
        limitationRate: "",
      };
    },
    //删除压价
    deletConfig(row) {
      this.regionList.forEach((res, index) => {
        if (res.id == row.id) {
          this.regionList.splice(index, 1);
        }
      });
    },
    // 压价配置编辑
    editConfig(row) {
      this.editPriceShow = true;
      if (row) {
        this.ifedmit = true;
        this.rowId = row.id;
        this.editPriceForm = {
          lower: row.lower,
          upper: row.upper,
          limitationRate: row.limitationRate,
          limitationLimit: row.limitationLimit,
        };
      } else {
        this.ifedmit = false;
      }
    },
    // 打开压价设置弹框
    configuration() {
      _api.limitationList().then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.addPriceShow = true;
          this.regionList = res.data || [];
          if (this.regionList.length > 0) {
            this.regionList.forEach((item) => {
              if (item.isBan == "01") {
                item.isBan = true;
              } else {
                item.isBan = false;
              }
              if (item.isWarn == "01") {
                item.isWarn = true;
              } else {
                item.isWarn = false;
              }
            });
          }
        }
      });
    },

    //报价备注
    remarkAdd(mark) {
      this.Quotationremarks = this.Quotationremarks + mark;
    },
    //手动推送报价
    hdstandsSubmit() {
      this.Manualpushshow = true;
      this.issave = false;
      this.Pushprice = "";
      this.Quotationremarks = "";
    },
    //提交推送报价
    eventualSubmit() {
      if (!this.Quotationremarks) {
        return this.$message.error("请输入报价备注！");
      }
      if (this.calculationamount.priceState == "02") {
        return this.$message.error("修改报价与原报价金额相同，暂无法提交!");
      }
      this.codeLoading = true;
      _api
        .pushPriceoffer({
          currentQuotePrice: this.allQuote.quotePriceMax,
          orderNo: this.priceorderNo,
          merchantId: this.allQuote.merchantIdMatrix,
          quoteNote: this.Quotationremarks,
          quotePrice: this.Pushprice,
        })
        .then((res) => {
          if (res.code == 1) {
            if (res.data.errorCode == "00") {
              this.$message.error(
                "最高报价发生变化，已为你自动刷新，请重新点击提交报价!"
              );
              //保存报价
              this.Reserveprice();
              _api
                .getPushQuoteRecord({ orderNo: res.data.orderNo })
                .then((res) => {
                  if (res.code == 1) {
                    this.allQuote = JSON.parse(JSON.stringify(res.data));
                    this.firstQuote = res.data.firstQuote || [];
                    this.reImgQuote = res.data.reImgQuote || {};
                    //默认首次取价
                    this.switchid = "1";
                    this.pushPricedatalist = this.firstQuote;
                    this.$forceUpdate();
                  }
                });
            } else {
              this.$message({
                message: "手动推送报价成功！",
                type: "success",
              });
              //关闭弹窗刷新列表
              this.pricePushshow = false;
              this.Manualpushshow = false;
              this.handleCurrentChange();
            }
          }
          this.codeLoading = false;
        })
        .catch((err) => {
          this.codeLoading = false;
        });
    },
    //报价推送切换
    switchover(id) {
      this.switchid = id;
      //接口这样设计取值，有问题骂接口
      if (this.switchid == "1") {
        //首次取价格
        this.pushPricedatalist = this.firstQuote;
      } else if (this.switchid == "2") {
        //补拍1
        this.pushPricedatalist = this.reImgQuote["1"] || [];
      } else if (this.switchid == "3") {
        //补拍2
        this.pushPricedatalist = this.reImgQuote["2"] || [];
      } else {
        //补拍3
        this.pushPricedatalist = this.reImgQuote["3"] || [];
      }
    },
    //手动报价
    operationPush(row) {
      console.log(row);
      this.operationdatalist = row;
      this.handsRecordershow = true;
    },
    //秒转化为分秒
    getTimes(t) {
      let h = parseInt((t / 60 / 60) % 24);
      let m = parseInt((t / 60) % 60);
      let s = parseInt(t % 60);
      //三元表达式 补零 如果小于10 则在前边进行补零 如果大于10 则不需要补零
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return `${m}分${s}秒`;
    },
    //保存报价
    Reserveprice() {
      if (!this.Pushprice) {
        return this.$message.error("请输入报价！");
      }
      _api
        .quoteOrderPriceCompute({
          merchantId: this.allQuote.merchantIdMatrix,
          orderNo: this.priceorderNo,
          quotePrice: this.Pushprice,
        })
        .then((res) => {
          if (res.code == 1) {
            this.issave = true;
            this.calculationamount = res.data;
            //若即将锁定回收商最后一次报价，有报价备注，则备注输入框默认回填备注内容
            this.Quotationremarks = res.data.quoteNoteLock || "";
            //赋值金额
            this.priceAllList[0].num = res.data.compute.diffFundPrice || 0;
            this.priceAllList[1].num =
              res.data.compute.quoteChannelServerFee || 0;
            this.priceAllList[2].num = res.data.compute.allAddPrice || 0;
            this.priceAllList[3].num = res.data.compute.angPowSmStaff || 0;
            this.priceAllList[4].num = res.data.compute.angPowStaff || 0;
            this.priceAllList[5].num = res.data.compute.userShowPrice || 0;
          }
        });
    },
    //报价金额
    pushchange(e) {
      console.log(e, "kl", this.Pushprice);
    },
    //outType== 'fx' || row.outType == 'lb' || row.outType == 'lb2'
    judgmentouttype(outType) {
      if (
        outType.indexOf("fx") !== -1 ||
        outType == "lb" ||
        outType == "lb2" ||
        outType == "honor"
      ) {
        return true;
      }
    },
    //推送报价
    offerPush(orderNo, outType) {
      console.log(outType);
      this.priceorderNo = orderNo;
      this.outType = outType;
      this.switchid = "1";
      //外部订单-立本渠道的订单(lb,lb2)，繁星自有矩阵订单(fx)
      if (
        outType.indexOf("fx") !== -1 ||
        outType == "lb" ||
        outType == "lb2" ||
        outType == "honor"
      ) {
        this.copykeepList = this.notekeepList;
      } else {
        this.copykeepList = this.notekeepList.slice(0, 2);
      }
      _api.getPushQuoteRecord({ orderNo }).then((res) => {
        if (res.code == 1) {
          this.allQuote = JSON.parse(JSON.stringify(res.data));
          this.firstQuote = res.data.firstQuote || [];
          this.reImgQuote = res.data.reImgQuote || {};
          //默认首次取价
          this.pushPricedatalist = this.firstQuote;
          this.pricePushshow = true;
          if (
            res.data.outEndQuotePriceTime &&
            res.data.outEndQuotePriceTime != -1
          ) {
            this.Closingoffer = true;
            this.deadline4 = Date.now() + res.data.outEndQuotePriceTime * 1000;
          }
        }
      });
    },
    //功能情况设置显示值
    setFunctionType(v) {
      let { isSystem, unableDetectionCause, functionList, isOtherTitileShow } =
        this.imeiTestProps;
      // 其他功能 系统检测||无法检测&&功能项存在&&（不是采货侠或者是采货侠订单且外部渠道为fx）
      if (
        (isSystem || unableDetectionCause) &&
        functionList.length &&
        (!v.isOneline || (v.isOneline && v.outType.includes("fx")))
      ) {
        isOtherTitileShow = true;
      } else {
        isOtherTitileShow = false;
      }
      this.imeiTestProps.isOtherTitileShow = isOtherTitileShow;
      // 02不是采货侠或者是采货侠订单且外部渠道为fx
      if (!v.isOneline || (v.isOneline && v.outType.includes("fx"))) {
        return (this.imeiTestProps.type = "02");
      }
    },

    // 上述02类型  外部渠道盛达lb或者lb2，屏蔽正常开机选项
    setFunctionData(v) {
      const fineness = v.fineness ?? { fineness: [] };
      v.detectionInfo = v.detectionInfo ?? {};
      let {
        isFinenessShow,
        functionList,
        systemTestList,
        photoArr,
        allSystemTestList,
      } = this.imeiTestProps;
      const { cameraTestImgFront, cameraTestImgRear } = v.detectionInfo;
      systemTestList = JSON.parse(JSON.stringify(allSystemTestList));
      let finenessData = Array.isArray(fineness.fineness)
        ? fineness.fineness
        : [];
      functionList = Array.isArray(functionList) ? functionList : [];
      // 盛达外部去除是否开机
      if (v.outType === "lb" || v.outType === "lb2") {
        functionList = functionList.filter(
          (v) => v.faultName !== "是否正常开机"
        );
      }
      // 成色渲染 是采货侠 && 并且存在成色数据
      if (v.isOneline && fineness && finenessData.length) {
        isFinenessShow = true;
        finenessData = Array.isArray(finenessData) ? finenessData : [];
        finenessData = finenessData.map((item) => {
          if (v.outType === "lb" || v.outType === "lb2") {
            item.value = item.value ?? [];
            item.value = item.value.map((v) => {
              return {
                valueText: v.isNormal ? "正常" : "异常",
                isNormal: v.isNormal,
              };
            });
          }
          return {
            name: item.label,
            value: item.value,
          };
        });
      } else {
        isFinenessShow = false;
      }
      // 设置功能机
      functionList = functionList.map((v) => {
        return {
          name: v.faultName,
          state: v.faultAnswer,
          reason: v.faultCause,
        };
      });
      // 检测类型动态更改值的key
      const systemKeys = ["mobileNetworks", "wlan", "bluetooth"];
      if (v.detectionInfo) {
        // 设置系统验机项
        systemTestList = systemTestList.map((item) => {
          item.value = v.detectionInfo[item.key] ?? "--";
          //前三个，正常的就是系统验机，异常的话是人工验机
          if (systemKeys.indexOf(item.key) >= 0) {
            if (v.detectionInfo[item.key] === "01") {
              item.isSystem = true;
            } else {
              item.isSystem = false;
            }
          }
          // 存在值才进行替换
          if (v.detectionInfo[item.tipKey]) {
            item.tips = v.detectionInfo[item.tipKey].replaceAll("&", "       ");
          }
          return item;
        });
      }

      // 设置前置旧机照片
      if (cameraTestImgFront) {
        photoArr.push(cameraTestImgFront);
      }
      // 设置后置
      if (cameraTestImgRear) {
        photoArr.push(cameraTestImgRear);
      }
      this.imeiTestProps = {
        ...this.imeiTestProps,
        functionList,
        isFinenessShow,
        finenessData,
        systemTestList,
        photoArr,
      };
    },

    // 功能情况接口
    getDetail(orderNo) {
      _api.getInspectionReport({ orderNo }).then((res) => {
        this.detailData = res.data;
        this.imeiTestProps.isLoadding = false;
      });
    },
    detectionfunction(orderNo, hideIdCheckState) {
      _api.hideIdgetOrderInfo({ orderNo }).then((res) => {
        this.detectionvisible = true;
        this.hideIdCheckState = hideIdCheckState;
        this.selectedStoreList = res.data || [];
      });
    },
    // 功能情况
    getFunction(v, outType) {
      this.externalorderType = outType;
      // 显示弹窗
      this.imeiTestProps = {
        ...this.imeiTestProps,
        phoneName: "",
        // 检测项
        tableData: [],
        // 功能情况
        functionList: [],
        // 系统检测结果
        systemTestResult: [],
        // 是否无法检测
        unableDetectionCause: "",
        // 是否系统检测且存在检测结果
        isSystem: false,
        // 其他功能标题显示，原需求模糊，抄详情写
        isOtherTitileShow: false,
        // 其他功能表格 原需求模糊，抄详情写
        isOtherTableShow: false,
        // 是否显示成色
        isFinenessShow: false,
        // 成色数据
        finenessData: [],
        // 旧机照片
        photoArr: [],
        // 系统验机项
        systemTestList: [],
        orderMode: "", //下单模式
        funcDetail: {},
        show: true,
        isLoadding: true,
      };
      // 请求详情
      this.getDetail(v);
    },
    // 成交订单验证码变化
    dealConfig(v) {
      const { barCode, code, currentData } = this.dealProp;
      // if (!barCode) {
      //   return this.$message.error("请输入条形码");
      // }
      if (!code) {
        return this.$message.error("请输入动态口令");
      }
      _api
        .ackAndBindBode({
          command: code,
          barCode,
          orderNo: currentData.orderNo,
        })
        .then((r) => {
          this.$message.success("操作成功");
          this.dealProp = {
            ...this.dealProp,
            show: false,
          };
          this.handleCurrentChange(this.pages.currentPage);
        });
    },
    //恢复订单
    recoverClick(row) {
      this.command = "";
      this.putDialogShow = true;
      this.doCancelorderNo = row.orderNo;
    },
    // 重新收款
    recollection(row) {
      this.command = "";
      this.recollectionDialogShow = true;
      this.doCancelorderNo = row.orderNo;
    },
    // 上/下架弹框确定
    putDialogSubmit(restoreType) {
      let params = {
        command: this.command,
        orderNo: this.doCancelorderNo,
        restoreType,
      };
      if (!this.command) {
        this.$message.error("请输入动态口令！");
        return;
      }
      this.putBtnLoading = true;
      _api
        .doCancelRestore(params)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg || "操作成功");
            this.putDialogShow = false;
            this.recollectionDialogShow = false;
            this.handleCurrentChange();
          }
          this.putBtnLoading = false;
        })
        .catch(() => {
          this.putBtnLoading = false;
        });
    },
    // 上/下架弹框关闭回调
    putDialogClosed() {
      this.command = "";
      // this.getListByMachineType();
    },
    // 加价弹窗关闭按钮
    revokeClose() {
      this.pcaddPric = null;
      this.plusPriceIF = false;
    },

    // 数字转换
    convertYypeNumer(num) {
      let v = num;
      // 是否类型正确
      let isType = true;
      if (typeof v !== "number") {
        isType = false;
        v = Number(v);
        if (isNaN(v)) {
          v = 0;
        }
      }
      return {
        isType,
        v,
      };
    },

    // 计算金额值
    computedMoney(num1, num2, isAdd = true) {
      const v1 = this.convertYypeNumer(num1);
      let v2 = this.convertYypeNumer(num2);
      if (!v1.isType && !v2.isType) {
        return "--";
      }
      v1.v = Math.abs(v1.v);
      v2.v = Math.abs(v2.v);

      return isAdd ? v1.v + v2.v : v1.v - v2.v;
    },
    //获取明秀
    getMoneyDetail(orderNo, cb) {
      _api.getQuoteCompute(orderNo).then((r) => {
        let data = setValue(r.data);
        data = {
          ...data,
          // 回收商实收价
          netQuotePrice: this.computedMoney(
            data.quotePrice,
            data.depreciatePrice,
            false
          ),
          // 员工红包
          angPow: this.computedMoney(data.angPowSmStaff, data.angPowStaff),
          // 公司压价返利
          companyRebate: this.computedMoney(
            data.orderCutPrice,
            data.allStaffCutPrice,
            false
          ),
        };
        cb(data);
      });
    },
    // 查看明细
    moneyDetail(v) {
      this.getMoneyDetail(v.orderNo, (data) => {
        this.dealDetailProps = {
          ...this.dealDetailProps,
          show: true,
          // 待确认和已取消代表未确认，返回false，其他作为true
          type: ["00", "10"].indexOf(v.state) < 0,
          data,
        };
      });
    },
    // 加价弹窗
    plusPrice(row) {
      console.log(row, "加价弹窗row");
      _api.pcaddPriceInfo({ orderNo: row.orderNo }).then((res) => {
        this.pcaddPric = res.data;
      });
      this.plusPriceIF = true;
    },
    // 内部加价弹窗
    isInsideInfo(row) {
      console.log(row, "内部审批弹窗row");
      _api.insideApplyInfo({ orderNo: row.orderNo }).then((res) => {
        this.insideInfo = res.data || {};
      });
      this.insideInfoShow = true;
    },
    rowStyle({ row, column, rowIndex, columnIndex }) {
      console.log(row);
      if (columnIndex == 6 && !(row.quoteNum == 0) && !(row.quoteNum == "--")) {
        return "text-decoration:underline;color:#21a4f1;cursor:pointer;";
      }
    },
    Bargain(row, column, cell, event) {
      console.log("@44@", row, column, cell, event);
      if (
        !(row.quoteNum == 0) &&
        !(row.quoteNum == "--") &&
        (column.property == "quoteNum" || column == "config")
      ) {
        console.log(row, "------------");
        // this.storeShow = false;
        this.viewrecords = true;
        _api
          .getUpdatedQuoteLog({
            merchantId: row.recycleMerchantId,
            orderNo: row.orderNo,
          })
          .then((res) => {
            console.log(res.data, "报价信息");
            this.getUpdatedQuoteLog = res.data.normal;
            this.remakeQuoteLog = res.data.remake;
          });
      }
    },
    ClearSeachParams() {
      this.$store.dispatch("tagsView/ClearSeachParams", "");
    },
    // 导出Excel
    clickExcel() {
      this.codeShow = true;
    },
    //
    closeExport() {
      this.codeShow = false;
      (this.code = ""), (this.codeLoading = false);
    },
    codeSubmit() {
      // const params = {
      //   startTime: this.SeachParams.startTime,
      //   endTime: this.SeachParams.endTime
      // };
      if (!this.code) {
        return this.$message.error("请输入谷歌动态码");
      }
      console.log(this.times);
      const params = {
        ...this.SeachParams,
        command: this.code,
      };
      if (!params.startTime && !params.otherStartTime && !params.ackStartTime) {
        return this.$message.error("请选择导出时间查询");
      }
      this.codeLoading = true;
      console.log(params);
      _api
        .orderExcel(params)
        .then((res) => {
          console.log(res);
          const content = res;
          this.codeShow = false;
          this.code = "";
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName = "订单列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.codeLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.codeLoading = false;
        });
    },
    // 跳转至换新订单
    goRenewOrder(row) {
      console.log(234, row);
      let routeData = this.$router.resolve({
        path: "/newMarkup/renewOrder",
        query: {
          type: "edit",
          orderNo: row.orderNo,
          activityId: row.activityId,
          companyId: row.companyId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    handleClick(row) {
      // this.$router.push({
      //   path: "/RecallOrder/Details",
      //   query: { type: "edit", id: row.orderNo },
      // });
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    remakeHandleClick(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.chxReimgOrderNo },
      });
      window.open(routeData.href, "_blank");
    },
    // 去重评订单详情
    toDetails(row) {
      this.$router.push({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.originalOrderNo },
      });
    },
    handleCurrentChange(val) {
      this.loading = true;
      if (val) {
        this.pages.currentPage = val;
      } else {
        this.pages.currentPage = 1;
      }
      const vo = {
        // 新加撮合渠道
        makeUpTypePlatform: this.SeachParams.makeUpTypePlatform,
        isRenewSubsidy: this.SeachParams.isRenewSubsidy,
        openMachineCondition: this.SeachParams.openMachineCondition,
        canOutQuote: this.SeachParams.canOutQuote,
        outPayChannel: this.SeachParams.outPayChannel,
        matchId: this.SeachParams.matchId,
        channel: this.SeachParams.channel,
        isBindBarCode: this.SeachParams.isBindBarCode,
        niceAckPayment: this.SeachParams.niceAckPayment,
        shopType: this.SeachParams.shopType,
        expState: this.SeachParams.expState,
        niceBizState: this.SeachParams.niceBizState,
        receiptType: this.SeachParams.receiptType,
        recycleWay: this.SeachParams.recycleWay,
        imei: this.SeachParams.imei,
        phoneName: this.SeachParams.phoneName,
        modelCode: this.SeachParams.modelCode,
        state: this.SeachParams.state,
        companyId: this.SeachParams.companyId,
        storeId: Number(this.SeachParams.storeId) || "",
        merchantId: this.SeachParams.merchantId,
        orderNo: this.SeachParams.orderNo,
        expressNo: this.SeachParams.expressNo,
        orderNoOneline: this.SeachParams.orderNoOneline,
        pageNum: this.pages.currentPage,
        pageSize: this.pages.pageSize,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        ackStartTime: this.SeachParams.ackStartTime,
        ackEndTime: this.SeachParams.ackEndTime,
        otherDateType: this.SeachParams.otherDateType,
        otherStartTime: this.SeachParams.otherStartTime,
        otherEndTime: this.SeachParams.otherEndTime,
        typeId: this.SeachParams.typeId,
        brandNo: this.SeachParams.brandNo,
        isNiceBiz: this.SeachParams.isNiceBiz,
        isNiceDevice: this.SeachParams.isNiceDevice,
        isReBuild: this.SeachParams.isReBuild,
        isInside: this.SeachParams.isInside,
        isExpected: this.SeachParams.isExpected,
        isChxRebuild: this.SeachParams.isChxRebuild,
        isSameImeiAutoQuote: this.SeachParams.isSameImeiAutoQuote,
        isReimg: this.SeachParams.isReimg,
        makeUpType: this.SeachParams.makeUpType,
        signType: this.SeachParams.signType,
        isAuto: this.SeachParams.isAuto,
        isEnablePay: this.SeachParams.isEnablePay,
        userIsCollect: this.SeachParams.userIsCollect,
        userPayeeMode: this.SeachParams.userPayeeMode,
        staffName: this.SeachParams.staffName,
        isUsedAddPrice: this.SeachParams.isUsedAddPrice,
        healthCode: this.SeachParams.healthCode,
      };
      _api
        .orderList(vo)
        .then((res) => {
          if (res.code === 1) {
            this.$emit("getUnPayUserOrders", res.data.unPayUserOrders);
            this.pages.total = res.data.ipage.total;
            this.itemTable = res.data.ipage.records;
            this.orderTableList = [];
            for (let i = 0; i < this.itemTable.length; i++) {
              this.itemArr = this.itemTable[i];
              const outMake = getOutNameMake(this.itemTable[i].outType).make;
              this.orderTableList.push(
                this.handleData({
                  ...this.itemArr,
                  outMake,
                  healthCode: getHealthCode(this.itemArr.healthCode),
                })
              ); // 数据重新组装重置为--
              if (i > 10) {
                return;
              }
            }
            this.loading = false;
            console.log(this.orderTableList);
          }
        })
        .finally(() => {
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    /********* 5-18新增 ************/
    copy(e) {
      const spanText = document.getElementById(e).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
    openImgBox(row) {
      console.log(row);
      this.imgShow = true;
      // this.imgList = e
      this.DialogImgList = [
        [...(row.images == "--" || !row.images ? [] : row.images)],
        [...(row.reImages == "--" || !row.reImages ? [] : row.reImages)],
      ];
      console.log(this.DialogImgList);
    },
    chooseImages(e) {
      if (e == 0) {
        this.imgIndex = 0;
        this.imgShowOnly = true;
      }
      if (e == 1) {
        this.imgIndex = 1;
        this.imgShowOnly = false;
      }
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
      this.imgList = [];
    },
    seeRemakeImg(row) {
      this.imgList.push(row.img);
      this.showViewer = true;
    },
    // 报价商家弹窗
    openStoreList(e) {
      console.log(e);
      this.baoPricestate = e.state;
      this.merchantName = e.merchantName;
      this.orderNo = e.orderNo;
      _api.orderStoreList({ orderNo: e.orderNo }).then((res) => {
        if (res.code == 1) {
          for (const item of res.data) {
            this.handleData(item);
          }
          this.storeList = res.data;
        }
      });
      this.toggleSelection();
      this.handleOutConfigColumns();
      this.storeShow = true;
    },
    storeClose() {
      this.storeShow = false;
      this.storeList = [];
    },
    recorderClose() {
      this.recorderShow = false;
      this.recorderList = [];
    },
    viewrecordsClose() {
      this.Closingoffer = false;
      this.viewrecords = false;
      this.pricePushshow = false;
    },
    // 所有不为真的第一层数据重置为--
    handleData(param) {
      for (const key in param) {
        if (Object.prototype.toString.call(param[key]) === "[object Object]") {
          this.handleData(param[key]);
        } else if (
          param[key] === null ||
          param[key] === undefined ||
          param[key] === ""
        ) {
          param[key] = "--";
        }
      }
      return param;
    },
    DialoghandleBtn(row) {
      if (row.state === "10") {
        this.dialogTitle = "查看取消原因";
        this.dialogcancel.remark = row.cancelCause;
      } else {
        this.dialogTitle = "查看作废原因";
        this.dialogcancel.remark = row.abolishCause;
      }
      this.dialogVisibleOrder = true;
    },
    //恢复记录
    recoveryRecord(row) {
      console.log(row);
      _api.getCancelRestore({ orderNo: row.orderNo }).then((res) => {
        if (res.code == 1) {
          this.recorderList = res.data;
        }
      });
      this.recorderShow = true;
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisibleOrder = false;
    },
    //弹窗确定
    submitPopupData() {
      this.dialogVisibleOrder = false;
    },
    // 从账号弹框
    seeSubInfo(row) {
      this.subInfo = {
        staffName: row.createStaffSubName,
        nickName: row.createStaffSubNickName,
        img: row.createStaffSubImg,
      };
      this.subInfoShow = true;
    },
    subInfoClose() {
      this.subInfo = {
        staffName: "",
        nickName: "",
        img: "",
      };
    },
    // 查看补拍记录
    openRemakeList(row) {
      this.remakeRecordsShow = true;
      this.remakeloading = true;
      this.islbfalg = row.outType;
      this.isOnelineRemake = row.isOneline;
      if (row.isOneline === true) {
        if (
          row.outType === "lb" ||
          row.outType === "lb2" ||
          row.outType.includes("fx")
        ) {
          _api.listRemakeByOrderNo({ orderNo: row.orderNo }).then((res) => {
            console.log(res.data, "补拍备注");
            this.remakeImgList = res.data;
            this.remakeloading = false;
          });
        } else {
          _api.listRemakeByOrderNoCHX({ orderNo: row.orderNo }).then((res) => {
            console.log(res.data, "补拍备注");
            this.remakeImgList = res.data;
            this.remakeloading = false;
          });
        }
      } else {
        _api.listRemakeByOrderNo({ orderNo: row.orderNo }).then((res) => {
          console.log(res.data, "补拍备注");
          this.remakeImgList = res.data;
          this.remakeloading = false;
        });
      }
    },
    // 查看议价信息
    openExpected(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/negotiatedOrder",
        query: { orderNo: row.orderNo },
      });
      window.open(routeData.href, "_blank");
      // this.expectedShow = true;
      // this.dtosloading = true;
      // _api.getExpectedInfo({ orderNo: row.orderNo }).then((res) => {
      //   this.expectedInfo = res.data;
      //   this.receiveDtosList = res.data.receiveDtos;
      //   this.submitDtosList = res.data.submitDtos;
      //   this.dtosloading = false;
      // });
    },
    expectedShowClose() {
      this.expectedInfo = {};
    },
    enquiryDetailsClose() {
      this.chxInfo = {};
    },
    viewDetails(row) {
      this.enquiryDetailsShow = true;
      _api.orderinfoRepeat({ orderNo: row.orderNo }).then((res) => {
        this.chxInfo = res.data;
      });
    },
    // 平台加价点击事件
    seeFinalMakeUpPrice(row) {
      this.platformMarkUpShow = true;
      _api.getMakeUpInfo({ orderNo: row.orderNo }).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.makeUpInfo = res.data;
        }
      });
    },
    //报价提示弹框确认按钮
    imakeUpSubmit() {
      if (this.makeUpTitle == "报价提示") {
        _api
          .platformMakeUp({ orderNo: this.makeUpOrderNo })
          .then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg || "操作成功");
              this.makeUpDialogVisible = false;
              this.handleCurrentChange(this.pages.currentPage);
            }
            this.imakeUpBtnLoading = false;
          })
          .catch(() => {
            this.imakeUpBtnLoading = false;
          });
      } else {
        _api
          .cancelMakeUp({ orderNo: this.makeUpOrderNo })
          .then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg || "操作成功");
              this.makeUpDialogVisible = false;
              this.handleCurrentChange(this.pages.currentPage);
            }
            this.imakeUpBtnLoading = false;
          })
          .catch(() => {
            this.imakeUpBtnLoading = false;
          });
      }
    },
    // 平台加价详情弹框关闭
    platformMarkUpClose() {
      this.makeUpInfo = {};
    },
    // 我要报价
    offerClick(row, type) {
      this.makeUpTitle = type == "up" ? "报价提示" : "撤销报价提示";
      this.makeUpOrderNo = row.orderNo;
      this.makeUpDialogVisible = true;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.command {
  display: flex;
  align-items: center;
}

.command .el-input {
  width: 80%;
}

.handsStyle {
  cursor: pointer;
  font-size: 12px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  text-decoration: underline;
  color: #4c84ff;
}

.biliStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.command .el-input {
  width: 80%;
}

.function_box {
  max-height: 700px;
  overflow: auto;
}

.img-el-heard {
  display: flex;
  justify-content: center;

  img {
    margin: 0px 0 -3px 3px;
  }
}

.foot_buttom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.OrderTable {
  .top_bar {
    background: white;
    display: flex;
    justify-content: space-between;

    .case {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;

      .case-title {
        display: flex;

        .case-title_icon {
          width: 20px;
          height: 20px;
        }

        .case-title_max {
          font-weight: bold;
          color: #333333;
          font-size: 18px;
          // width: 80px;
          height: 17px;
          line-height: 17px;
          margin-left: 5px;
          font-family: FZLanTingHei-B-GBK;
        }
      }
    }
  }

  .orderPagina {
    float: right;
    margin: 50px;
  }

  .order_list {
    .line_top {
      background-color: white;
      width: 100%;
      height: 10px;
    }

    .table_title {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: space-between;

      .left {
        flex: 1;
        display: flex;
        position: relative;
        align-items: center;

        // justify-content: space-between;
        div {
          margin-left: 30px;

          > div {
            margin-left: 0;
            width: 32px;
            height: 22px;
            background: #21d8c2;
            border-radius: 3px 11px 11px 3px;
            color: white;
            line-height: 22px;
            position: absolute;
            text-align: center;
            top: 0;
            left: -5px;
            margin-right: 10px;
          }

          > span {
            font-family: FZLanTingHei-DB-GBK;
            font-size: 14px;
            font-weight: 400;
            color: #0981ff;
          }

          > input {
            border: none;
            background: none;
            color: #0981ff;
            padding: 0;
            width: 140px !important;
            font-size: 14px;
          }

          > .copy {
            width: 36px;
            height: 16px;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            background: #ff6600;
            color: #fff;
            margin-left: 8px;
            border-radius: 4px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      .right {
        display: flex;
        color: #fff;

        .rightTrue {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ff687b;
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightFalse {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ffaf29;
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightFalseline {
          width: max-content;
          padding: 5px 10px;
          border: 1px solid #ff687b;
          border-radius: 14px !important;
          margin-right: 10px;
          text-align: center;
          font-size: 12px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          color: #ff687b;
        }

        .rightMid {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: rgba(102, 204, 153, 1);
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }
      }
    }
  }
}

// 表格样式+
.position {
  width: max-content;
  display: block;
  padding: 0px 4px;
  font-size: 14px;
  background-color: rgba(102, 204, 153, 1);
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.order-nice {
  // position: absolute;
  left: -80px;
  top: -1px;
  width: max-content;
  display: block;
  padding: 2px 8px;
  font-size: 14px;
  background-color: #81d3f8;
  margin: 0 auto;
  border-radius: 5px;
  color: #fff !important;
  text-align: center;
  margin-right: 10px;
}

.sty {
  margin-right: 10px;
  // position: absolute;
  // left: 0px;
  // top: -1px;
  width: max-content;
  background: #fca91f;
  border-radius: 14px;
  font-size: 14px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #0981ff;
  padding: 3px 10px;
}

.solo {
  position: absolute;
  left: -80px;
  top: -1px;
  width: max-content;
  background: #fca91f;
  border-radius: 11px;
  font-size: 14px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff !important;
  text-align: center;
  padding: 3px 10px;
  margin-right: 10px;
}

.solo2 {
  position: absolute;
  left: -80px;
  top: -1px;
  width: max-content;
  background: #7dc165;
  border-radius: 11px;
  font-size: 14px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff !important;
  text-align: center;
  padding: 3px 10px;
  margin-right: 10px;
}

.order-online {
  position: absolute;
  left: -80px;
  top: -1px;
  width: max-content;
  display: block;
  padding: 0 5px;
  font-size: 12px;
  margin: 0 auto;
  border: 1px solid #ff687b;
  border-radius: 20px;
  color: #ff687b !important;
  text-align: center;
  margin-right: 10px;
}

.images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-box > img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.images-box > span {
  color: #0981ff;
  cursor: pointer;
}

.color-green {
  color: rgba(102, 204, 153, 1);
}

.color-red {
  color: #ff687b;
}

.text-down {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}

// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.noteLayout {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isdeal {
  background: #ff687b;
  margin-right: 5px;
  display: inline-block;
}

.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}

.cellnum {
}

.autoshow::-webkit-scrollbar {
  display: none;
}

.onlyul {
  li {
    margin-top: 10px;
  }

  .firstli {
    margin: 0;
    color: #3a9aff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .firstli_span {
      color: #ccc;
    }
  }

  .secondli {
    color: #ff6600;
    // font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.quoteNotecss {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp: 2; //显示的行
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.add-price-title {
  font-weight: bold;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  border-left: 3px solid #0981ff;
  margin-bottom: 10px;
  color: #0981ff;
}

.add-price-conent {
  padding: 0 40px;
}

.expected_info {
  margin-bottom: 40px;

  .expected_info_title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #0981ff;
    padding-left: 15px;
  }

  .expected_info_title::before {
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    width: 3px;
    height: 21px;
    background: #0981ff;
    border-radius: 2px;
  }
}

.enable-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.platform_markup {
  font-size: 16px;
  color: #0981ff;
  margin-bottom: 10px;

  .grey_box {
    width: 100%;
    padding: 10px 20px;
    background-color: #f5f6fa;
    border-radius: 10px;
    margin-top: 20px;
    line-height: 30px;
    color: #333;
  }
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.deal_detail_title {
  // width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 10px;
  flex: 1;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    background: #0c82ff;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.deal_detail_title1 {
  // width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 10px;
}

.deal_detail_title2 {
  font-size: 14px;
  color: #ff0000;
}
.deal_detail_inner {
  margin-bottom: 20px;
  background: #f2f2f2;
  font-size: 12px;
  color: #111;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 6px;
}

.deal_detail_line {
  margin-bottom: 16px;
}

.deal_detail_line-title {
  padding-left: 10px;
  position: relative;
  margin-bottom: 6px;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

.deal_detail_line-tips {
  transform: translateX(-5px);
}

.deal_detail_line-tips-blue {
  color: #0981fe;
}

.deal_detail_line-tips-red {
  color: #ff0000;
}

.test-function {
  margin-top: 20px;
}

.artificial-test-info {
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646464;
  margin-bottom: 10px;
}

.system-result-title {
  color: #646464;
  font-size: 16px;
  margin-bottom: 10px;
}

.topPriceshoe {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.writquotation {
  margin-top: 15px;
  font-size: 16px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;

  span {
    font-size: 14px;
    color: #666666;
  }
}

.title_wt {
  margin-left: 10px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff;
  width: 36px;
  height: 17px;
  background: #ffaf29;
  border-radius: 8px 8px 8px 1px;
}

.title_ht {
  background: #4c84ff;
}

.qb_act {
  display: flex;
  margin: 10px 0;

  .qukliy_box {
    cursor: pointer;
    font-size: 13px;
    margin-right: 10px;
    line-height: 13px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #e4ecfd;
    color: rgb(9, 129, 255);
  }
}

.center_ptcon {
  margin: 20px 0;
  font-size: 15px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: bold;
  color: #333333;
}

.record_content {
  display: flex;
  width: 400px;
  height: 32px;
  background: #f9fbfd;
  border: 1px solid #d9e6ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 4px;
}

.scllo_center {
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  width: 100px;
  height: 30px;
  border-radius: 4px;
}

.action_scoll {
  color: #ffffff;
  background: #4c84ff;
}

.tp_left {
  display: flex;
  width: 240px;
  height: 76px;
  background: #f5f6fa;
  border-radius: 8px 4px 4px 4px;
}

.tpl_first {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 76px;
  background: #e6edfd;
  border-radius: 8px 0px 0px 4px;
}

.flost_tf {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 60px;
  height: 16px;
  line-height: 15px;
  background: #ff687b;
  border-radius: 8px 8px 8px 1px;
  font-size: 12px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff;
}

.tpl_first > p {
  text-align: center;
  width: 90px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.center_tf {
  width: 134px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
}

.call_accounts_info {
  margin: 10px 0;
}

.call_accounts_check {
  margin-top: 10px;
  margin-bottom: 10px;
  // padding-left: 14px;
}

.call_accounts_result {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.baocunPrice {
  margin: 14px 0;
  display: flex;
  width: 651px;
  height: 70px;
  background: #f5f6fa;
  border-radius: 4px;
}

.xiuga_center {
  width: 120px;
  height: 70px;
  background: #f5f6fa;
  border-radius: 8px 4px 4px 4px;
  margin: 14px 0;
  margin-left: 12px;
}

.bp_pstyle {
  p {
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #666666;
  }
}

.fs16 {
  font-size: 14px;
  margin-bottom: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

::v-deep .el-input {
  input[type="number"] {
    padding-right: 0px;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    // 解决el-input设置类型为number时，中文输入法光标上移问题
    line-height: 1px !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.detail-price-box {
  font-size: 14px;
  color: #000;
  .outPay {
    display: flex;
    align-items: center;
    height: 30px;
    .outPay_title {
      font-weight: 700;
    }
    .outPay_tag {
      border: 1px solid #ff687b;
      padding: 0 3px;
      border-radius: 5px;
      margin-left: 10px;
      color: #ff687b;
    }
  }
  .outPay_info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
  }

  .outPay_info1 {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 43px;
  }
  .mt-20 {
    margin-top: 20px;
  }
}
.isdeal1 {
  border: 1px solid #f59a23;
  display: inline-block;
  width: max-content;
  padding: 0px 4px;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 5px;
  color: #f59a23;
  text-align: center;
  margin-left: 5px;
}
/deep/.el-statistic {
  width: 45px;
  display: inline-flex;
  height: 13px;
  margin-top: 2px;
  align-items: center;
}
.address-box {
  background-color: #f5f6fa;
  color: #666;
  width: 400px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.price {
  color: red;
}
</style>
